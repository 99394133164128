import { Link } from 'react-router-dom';
import img from '../../../assets/soldador.png';
import style from './ChiefFinancialOfficer.module.css';

import React from 'react';

function SoldadorIndustrial() {
  const imgg = img;
  const titulo = 'Soldador Industrial';

  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Soldador Industrial</h1>
          <div className={style.jobDescription}>
            <p>
            A Pathway Consulting está à procura de um Soldador Industrial para uma empresa do setor de petróleo e gás, com sede em Cabo Delgado. O candidato selecionado será responsável pela execução de atividades de soldagem em estruturas metálicas e tubulações, garantindo a integridade e a qualidade das soldas.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} alt="Soldador Industrial" />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Nível superior ou formação técnica em Soldagem, Engenharia Mecânica, ou áreas correlatas, com ênfase em soldagem industrial e processos de fabricação.</li>
          <li>Mínimo de 5 anos de experiência em atividades similares no setor industrial, com foco em soldagem de equipamentos de perfuração terrestre e offshore, incluindo reparos e manutenção de estruturas pesadas.</li>
          <li>Experiência comprovada em soldagem de materiais como aço carbono, inoxidável, e ligas especiais, com conhecimento em processos como MIG, TIG, Eletrodo Revestido (SMAW) e soldagem subaquática.</li>
          <li>Experiência em realizar inspeções de solda e testes de qualidade, utilizando métodos não destrutivos (NDT) como ultrassom, raios-X e inspeção visual para garantir a integridade das soldas em componentes críticos.</li>
          <li>Fluência em inglês.</li>
        </ul>
      </div>

      {/*<div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Gestão de faturação e emissão de notas de honorários.</li>
          <li>Gestão de compras e aquisições para o escritório.</li>
          <li>Controlo de contas.</li>
          <li>Gestão de contas a pagar e a receber.</li>
          <li>Reconciliações bancárias e elaboração de relatórios financeiros.</li>
          <li>Apoio na elaboração e acompanhamento de orçamentos.</li>
          <li>Garantir a conformidade com as normas financeiras e fiscais aplicáveis.</li>
        </ul>
      </div>*/}

      <div className={style.offerSection}>
        <h2 className={style.sectionTitle}>Oferecemos:</h2>
        <ul className={style.offerList}>
          <li>Ambiente de trabalho dinâmico e colaborativo.</li>
          <li>Oportunidades de desenvolvimento profissional.</li>
        </ul>
      </div>

      <div className={style.applySection}>
      <Link to={`/candidaturaspersonal/${encodeURIComponent(titulo)}`}>
          <button className={style.applyButton}>Candidatar-se</button>
        </Link>
      </div>
    </div>
  );
}

export default SoldadorIndustrial;
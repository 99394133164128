import { Link } from 'react-router-dom';
import img from '../../../assets/torno.PNG';
import style from './ChiefFinancialOfficer.module.css';

import React from 'react';

function OperadorTorno() {
  const imgg = img;
  const titulo = 'Operador de Torno';

  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Operador de Torno</h1>
          <div className={style.jobDescription}>
            <p>
            A Pathway Consulting está à procura de um Operador de Torno para uma empresa do setor de petróleo e gás, com sede em Cabo Delgado. O candidato selecionado será responsável pela operação, manutenção e ajuste de tornos, garantindo a precisão na usinagem de peças e componentes. Além disso, será incumbido de realizar diagnósticos e resolver problemas relacionados ao funcionamento do equipamento, assegurando a eficiência e a qualidade na produção de peças essenciais para as operações da empresa.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} alt="Operador de Torno" />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Proficiente na operação de tornos manuais e CNC, com experiência em corte, perfuração e usinagem de metais.</li>
          <li>Capacidade de trabalhar com tolerâncias rigorosas e garantir a precisão na usinagem de peças metálicas.</li>
          <li>Habilidade comprovada em interpretar desenhos técnicos e esquemas de usinagem.</li>
          <li>Competência na seleção adequada de ferramentas de corte e usinagem para diferentes tipos de metais e operações.</li>
          <li>Familiaridade com práticas de manutenção preventiva e corretiva de tornos e outros equipamentos de usinagem.</li>
          <li>Capacidade de colaborar efetivamente com outros membros da equipe de produção e engenharia.</li>
          <li>Conhecimento das normas de segurança e regulamentos aplicáveis ao ambiente de trabalho em usinagem.</li>
          <li>Fluência em inglês.</li>
        </ul>
      </div>

      {/*<div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Gestão de faturação e emissão de notas de honorários.</li>
          <li>Gestão de compras e aquisições para o escritório.</li>
          <li>Controlo de contas.</li>
          <li>Gestão de contas a pagar e a receber.</li>
          <li>Reconciliações bancárias e elaboração de relatórios financeiros.</li>
          <li>Apoio na elaboração e acompanhamento de orçamentos.</li>
          <li>Garantir a conformidade com as normas financeiras e fiscais aplicáveis.</li>
        </ul>
      </div>*/}

      <div className={style.offerSection}>
        <h2 className={style.sectionTitle}>Oferecemos:</h2>
        <ul className={style.offerList}>
          <li>Ambiente de trabalho dinâmico e colaborativo.</li>
          <li>Oportunidades de desenvolvimento profissional.</li>
        </ul>
      </div>

      <div className={style.applySection}>
      <Link to={`/candidaturaspersonal/${encodeURIComponent(titulo)}`}>
          <button className={style.applyButton}>Candidatar-se</button>
        </Link>
      </div>
    </div>
  );
}

export default OperadorTorno;
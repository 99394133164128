import { Link, useNavigate } from 'react-router-dom';
import style from './FielInfoForm.module.css';
import { useState } from 'react';

function FielInfoForm() {
  
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedd, setIsCheckedd] = useState(false);
  const [isCheckedfera, setIsCheckedfera] = useState(false);
  const [isCheckedeqpam, setIsCheckedeqpam] = useState(false);
  const [isCheckedsect, setIsCheckedsect] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const [formData, setFormData] = useState({
    escolaridade: [],
    instituicao: '',
    areaFormacao: '',
    experienciaAnos: '',
    tarefas: [],
    ferramentas: [],
    equipamentos: [],
    sectores: [],
    fluenciaIngles: '',
    referencias: '',
    cvUpload: null,
    declaracao: '',
  });

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: checked ? [...(prev[name] || []), value] : prev[name].filter((v) => v !== value),
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      cvUpload: event.target.files[0],
    }));
  };

  const handleSubmit = () => {
    localStorage.setItem('formData', JSON.stringify(formData));
    navigate('/candidaturas');
  };

  return (
    <div className={style.formContainer}>
      <h2 className={style.sectionTitle}>Formação Acadêmica</h2>

      <label className={style.label}>
        Qual é o teu nível de escolaridade *
        <div className={style.checkboxGroup}>
          <label>
            <input type="checkbox" name="escolaridade" value="Ensino Médio completo" onChange={handleCheckboxChange} /> Ensino Médio completo
          </label>
          <label>
            <input type="checkbox" name="escolaridade" value="Formação Técnica" onChange={handleCheckboxChange} /> Formação Técnica
          </label>
          <label>
            <input type="checkbox" name="escolaridade" value="Superior" onChange={handleCheckboxChange} /> Superior (Licenciatura)
          </label>
          <label>
            <input type="checkbox" name="escolaridade" value="Outro" onChange={(e) => { handleCheckboxChange(e); setIsChecked(!isChecked); }} /> Outro (Especificar)
          </label>
          {isChecked && <label className={style.label}>
            Especifique a escolaridade
            <input type="text" name="escolaridadeOutro" onChange={handleInputChange} className={style.input} />
          </label>}
        </div>
      </label>

      <label className={style.label}>
        Qual é a tua instituição de ensino
        <input type="text" name="instituicao" onChange={handleInputChange} className={style.input} />
      </label>

      <label className={style.label}>
        Em que área é a tua formação *
        <div className={style.radioGroup}>
          <label> <input type="radio" name="areaFormacao" value="Logística" onChange={handleInputChange} />
            Logística
          </label>
          <label>
            <input type="radio" name="areaFormacao" value="Gestão de Armazéns" onChange={handleInputChange} />
            Gestão de Armazéns
          </label>
          <label>
            <input type="radio" name="areaFormacao" value="Engenharia" onChange={handleInputChange} />
            Engenharia
          </label>
          <label>
            <input type="radio" name="areaFormacao" value="Outro" onChange={(e) => { handleInputChange(e); setSelectedOption(e.target.value); }} />
            Outro (Especificar)
          </label>
          {selectedOption === 'Outro' && <label className={style.label}> Especifique a formação <input type="text" name="areaFormacaoOutro" onChange={handleInputChange} className={style.input} />
          </label>}
        </div>
      </label>

      <h2 className={style.sectionTitle}>Experiência e Conhecimentos Técnicos</h2>

      <label className={style.label}>
        Quantos anos de experiência tem como fiel de Armazém ou em funções semelhantes *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="experienciaAnos" value="Menos de 6 meses" onChange={handleInputChange} /> Menos de 6 meses
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="Entre 6 meses e 1 ano" onChange={handleInputChange} /> Entre 6 meses e 1 ano
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="Entre 1 e 3 anos" onChange={handleInputChange} /> Entre 1 e 3 anos
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="Mais de 3 anos" onChange={handleInputChange} /> Mais de 3 anos
          </label>
        </div>
      </label>

      <label className={style.label}>
        Quais das seguintes tarefas realizou em funções anteriores *
        <div className={style.checkboxGroup}>
          <label>
            <input type="checkbox" name="tarefas" value="Gestão de recebimento e armazenamento de mercadorias" onChange={handleCheckboxChange} /> Gestão de recebimento e armazenamento de mercadorias
          </label>
          <label>
            <input type="checkbox" name="tarefas" value="Organização e manutenção de estoques" onChange={handleCheckboxChange} /> Organização e manutenção de estoques
          </label>
          <label>
            <input type="checkbox" name="tarefas" value="Inspeção para conformidade com padrões de segurança e qualidade" onChange={handleCheckboxChange} /> Inspeção para conformidade com padrões de segurança e qualidade
          </label>
          <label>
            <input type="checkbox" name="tarefas" value="Coordenação da movimentação de produtos" onChange={handleCheckboxChange} /> Coordenação da movimentação de produtos
          </label>
          <label>
            <input type="checkbox" name="tarefas" value="Preparação de relatórios de estoque" onChange={handleCheckboxChange} /> Preparação de relatórios de estoque
          </label>
          <label>
            <input type="checkbox" name="tarefas" value="Outro" onChange={(e) => { handleCheckboxChange(e); setIsCheckedd(!isCheckedd); }} /> Outro (especificar)
          </label>
          {isCheckedd && <label className={style.label}>
            Especifique a tarefa
            <input type="text" name="tarefasOutro" onChange={handleInputChange} className={style.input} />
          </label>}
        </div>
      </label>

      <label className={style.label}>
        Quais das seguintes ferramentas ou sistemas de gestão de inventário utilizou *
        <div className={style.checkboxGroup}>
          <label>
            <input type="checkbox" name="ferramentas" value="ERP PHC" onChange={handleCheckboxChange} /> ERP PHC
          </label>
          <label>
            <input type="checkbox" name="ferramentas" value="Excel" onChange={handleCheckboxChange} /> Excel
          </label>
          <label>
            <input type="checkbox" name="ferramentas" value="Outro" onChange={(e) => { handleCheckboxChange(e); setIsCheckedfera(!isCheckedfera); }} /> Outro (especificar)
          </label>
          {isCheckedfera && <label className={style.label}>
            Especifique a ferramenta
            <input type="text" name="ferramentasOutro" onChange={handleInputChange} className={style.input} />
          </label>}
        </div>
      </label>

      <label className={style.label}>
        Qual é a sua experiência com equipamentos de armazém *
        <div className={style.checkboxGroup}>
          <label>
            <input type="checkbox" name="equipamentos" value="Operação de empilhadores" onChange={handleCheckboxChange} /> Operação de empilhadores
          </label>
          <label>
            <input type="checkbox" name="equipamentos" value="Outro" onChange={(e) => { handleCheckboxChange(e); setIsCheckedeqpam(!isCheckedeqpam); }} /> Outro (especificar)
          </label>
          {isCheckedeqpam && <label className={style.label}>
            Especifique o equipamento
            <input type="text" name="equipamentosOutro" onChange={handleInputChange} className={style.input} />
          </label>}
        </div>
      </label>

      <h2 className={style.sectionTitle}>Referências e Documentos Adicionais</h2>

      <label className={style.label}>
        Referências (opcional)
        <textarea name="referencias" onChange={handleInputChange} className={style.textarea}></textarea>
      </label>

      <label className={style.label}>
        Upload do CV (obrigatório)
        <input type="file" name="cvUpload" onChange={handleFileChange} className={style.input} />
      </label>

      <label className={style.label}>
        Declaração de Compromisso
        <textarea name="declaracao" onChange={handleInputChange} className={style.textarea}></textarea>
      </label>

      <div className={style.buttonGroup}>
        <Link to={'/candidaturas'}>
          <button className={style.buttonBack}>Voltar</button>
        </Link>
        <button type="button" onClick={handleSubmit} className={style.buttonNext}>Seguinte</button>
      </div>
    </div>
  );
}

export default FielInfoForm;

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import CandidatarPersonalForm from './components/layout/CandidatarPersonalForm.js';
import Navbar from './components/layout/Navbar.js';
import Candidaturas from './components/pages/Candidaturas.js';
import CandidaturasPersonal from './components/pages/CandidaturasPersonal.js';
import Contactos from './components/pages/Contactos.js';
import Footer from './components/pages/Footer.jsx';
import Inicio from './components/pages/Inicio.js';
import Servicos from './components/pages/Servicos.jsx';
import Sobre from './components/pages/Sobre.js';
import VagaDescription from './components/pages/VagaDescription.js';
// import AssistenteAdministrativa from './components/layout/AssistenteForm.js';
import SoldadorIndustrial from './components/layout/vagas/SoldadorIndustrial.js';
import Vagas from './components/pages/Vagas.js';
import ScrollToTop from './components/ScrollToTop.js';

function App() {
  return (
    <div className="App">
      <Router>
      <ScrollToTop />
        <Navbar />

        <Routes>
          <Route path='/' element={<Inicio />} />
          <Route path="/test" element={<SoldadorIndustrial/>}/>
          <Route path='/servicos' element={<Servicos  />} />
          <Route path='/sobre' element={<Sobre />} />
          <Route path='/vagas' element={<Vagas />} />
          <Route path='/contactos' element={<Contactos />} />
          <Route path='/candidaturas' element={<Candidaturas />} />
          <Route path='/candidaturaspersonal' element={<CandidaturasPersonal />} />
          <Route path='/descricaovaga/:titulo' element={<VagaDescription />} />
          <Route path='/candidaturaspersonal/:titulo' element={<CandidatarPersonalForm />} />
          <Route path='/candidaturaspersonall/:titulo' element={<CandidaturasPersonal />} />
          

          
        </Routes>

        <Footer />
      </Router>

    </div>
  );
}

export default App;

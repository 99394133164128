import { Link, useNavigate } from 'react-router-dom';
import style from './FielInfoForm.module.css';
import { useState, useEffect } from 'react';

function SoldadorForm() {
  const navigate = useNavigate();

  const [previousFormData, setPreviousFormData] = useState(null);
  const [isCheckedOutroProcesso, setIsCheckedOutroProcesso] = useState(false);
  const [isCheckedOutroInspecao, setIsCheckedOutroInspecao] = useState(false);
  const [isCheckedOutroMaterial, setIsCheckedOutroMaterial] = useState(false);
  const [isCheckedOutroProcessoPerfuratriz, setIsCheckedOutroProcessoPerfuratriz] = useState(false);

  const [formData, setFormData] = useState({
    experienciaSoldagem: '',
    processosSoldagem: '',
    processosSoldagemOutro: '',
    inspecaoSoldas: '',
    inspecaoSoldasOutro: '',
    planejamentoSoldas: '',
    planejamentoSoldasOutro: '',
    experienciaPerfuratriz: '',
    materiaisEquipamentos: '',
    materiaisEquipamentosOutro: '',
    soldagemSubmersa: '',
    processosPerfuratriz: '',
    processosPerfuratrizOutro: '',
    altaPressao: '',
    localDificilAcesso: '',
    cvUpload: null,
    declaracao: ''
  });

  useEffect(() => {
    const savedData = localStorage.getItem('candidatarPersonalFormData');
    if (savedData) {
      setPreviousFormData(JSON.parse(savedData));
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData(prev => ({
      ...prev,
      cvUpload: event.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const previousFormData = JSON.parse(localStorage.getItem('formData')) || {};
  
      const formDataWithoutFile = { ...formData };
      delete formDataWithoutFile.cvUpload;
  
      const combinedData = {
        ...previousFormData,
        ...formDataWithoutFile,
      };

      const submitFormData = new FormData();
      submitFormData.append('formData', JSON.stringify(combinedData));
  
      if (formData.cvUpload) {
        submitFormData.append('cvUpload', formData.cvUpload);
      }
  
      const response = await fetch('https://candidaturas.pathway.co.mz/backend/adicionarcandidato.php', {
        method: 'POST',
        body: submitFormData,
      });
  
      const result = await response.json();
  
      if (result.success) {
        console.log('CV uploaded to:', result.cv_path);
        console.log('Dados:', result.combinedData);
        localStorage.setItem('formData', JSON.stringify(combinedData));
        navigate('/');
        alert("Candidatura Submetida com sucesso");
      } else {
        console.error('Erro ao salvar:', result.message);
        alert('Erro ao salvar os dados. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao salvar os dados. Por favor, tente novamente.');
    }
  };
  
  return (
    <div className={style.formContainer}>
      <h2 className={style.sectionTitle}>Experiência em Soldagem Industrial</h2>
      
      <label className={style.label}>
        Tem experiência em soldagem de metais pesados e complexos em ambientes industriais? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="experienciaSoldagem" value="Sim, com ampla experiência em soldagem de metais pesados" onChange={handleInputChange} />
            Sim, com ampla experiência em soldagem de metais pesados
          </label>
          <label>
            <input type="radio" name="experienciaSoldagem" value="Sim, com experiência moderada em soldagem de metais pesados" onChange={handleInputChange} />
            Sim, com experiência moderada em soldagem de metais pesados
          </label>
          <label>
            <input type="radio" name="experienciaSoldagem" value="Tenho experiência apenas com soldagem de metais leves" onChange={handleInputChange} />
            Tenho experiência apenas com soldagem de metais leves
          </label>
          <label>
            <input type="radio" name="experienciaSoldagem" value="Não tenho experiência com soldagem de metais pesados" onChange={handleInputChange} />
            Não tenho experiência com soldagem de metais pesados
          </label>
        </div>
      </label>

      <label className={style.label}>
        Quais tipos de processos de soldagem você já utilizou em ambientes industriais? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="processosSoldagem" value="MIG, TIG, Eletrodo Revestido (SMAW) e Submersa (SAW)" onChange={handleInputChange} />
            MIG, TIG, Eletrodo Revestido (SMAW) e Submersa (SAW)
          </label>
          <label>
            <input type="radio" name="processosSoldagem" value="Apenas MIG e TIG" onChange={handleInputChange} />
            Apenas MIG e TIG
          </label>
          <label>
            <input type="radio" name="processosSoldagem" value="Somente Eletrodo Revestido (SMAW)" onChange={handleInputChange} />
            Somente Eletrodo Revestido (SMAW)
          </label>
          <label>
            <input type="radio" name="processosSoldagem" value="Não tenho experiência com esses processos" onChange={handleInputChange} />
            Não tenho experiência com esses processos
          </label>
          <label>
            <input 
              type="radio" 
              name="processosSoldagem" 
              value="Outro" 
              onChange={(e) => {
                handleInputChange(e);
                setIsCheckedOutroProcesso(!isCheckedOutroProcesso);
              }}
            />
            Outro
          </label>
          {isCheckedOutroProcesso && (
            <input 
              type="text" 
              name="processosSoldagemOutro" 
              onChange={handleInputChange} 
              className={style.input} 
              placeholder="Especifique outros processos"
            />
          )}
        </div>
      </label>

      <label className={style.label}>
        Qual a sua experiência em realizar inspeções de qualidade em soldas industriais? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="inspecaoSoldas" value="Realizo inspeções detalhadas e tenho experiência com testes de NDT" onChange={handleInputChange} />
            Realizo inspeções detalhadas e tenho experiência com testes de NDT
          </label>
          <label>
            <input type="radio" name="inspecaoSoldas" value="Realizo inspeções visuais básicas e uso ferramentas de medição" onChange={handleInputChange} />
            Realizo inspeções visuais básicas e uso ferramentas de medição
          </label>
          <label>
            <input type="radio" name="inspecaoSoldas" value="Não realizo inspeções de soldas, mas sou orientado por outros técnicos" onChange={handleInputChange} />
            Não realizo inspeções de soldas, mas sou orientado por outros técnicos
          </label>
          <label>
            <input type="radio" name="inspecaoSoldas" value="Não tenho experiência em inspeção de soldas" onChange={handleInputChange} />
            Não tenho experiência em inspeção de soldas
          </label>
          <label>
            <input 
              type="radio" 
              name="inspecaoSoldas" 
              value="Outro" 
              onChange={(e) => {
                handleInputChange(e);
                setIsCheckedOutroInspecao(!isCheckedOutroInspecao);
              }}
            />
            Outro
          </label>
          {isCheckedOutroInspecao && (
            <input 
              type="text" 
              name="inspecaoSoldasOutro" 
              onChange={handleInputChange} 
              className={style.input} 
              placeholder="Especifique sua experiência"
            />
          )}
        </div>
      </label>

      <h2 className={style.sectionTitle}>Experiência Específica em Equipamentos de Perfuração</h2>

      <label className={style.label}>
        Tem experiência em soldagem de componentes de perfuratrizes? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="experienciaPerfuratriz" value="Sim, com experiência em várias partes da perfuratriz" onChange={handleInputChange} />
            Sim, com experiência em várias partes da perfuratriz
          </label>
          <label>
            <input type="radio" name="experienciaPerfuratriz" value="Sim, mas apenas em algumas partes específicas" onChange={handleInputChange} />
            Sim, mas apenas em algumas partes específicas
          </label>
          <label>
            <input type="radio" name="experienciaPerfuratriz" value="Não tenho experiência em soldagem de perfuratrizes" onChange={handleInputChange} />
            Não tenho experiência em soldagem de perfuratrizes
          </label>
        </div>
      </label>

      <label className={style.label}>
        Quais materiais já soldou em equipamentos de perfuração terrestre? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="materiaisEquipamentos" value="Aço carbono, aço inoxidável e ligas especiais" onChange={handleInputChange} />
            Aço carbono, aço inoxidável e ligas especiais
          </label>
          <label>
            <input type="radio" name="materiaisEquipamentos" value="Apenas aço carbono" onChange={handleInputChange} />
            Apenas aço carbono
          </label>
          <label>
            <input type="radio" name="materiaisEquipamentos" value="Não tenho experiência com soldagem desses materiais" onChange={handleInputChange} />
            Não tenho experiência com soldagem desses materiais
          </label>
          <label>
            <input 
              type="radio" 
              name="materiaisEquipamentos" 
              value="Outro" 
              onChange={(e) => {
                handleInputChange(e);
                setIsCheckedOutroMaterial(!isCheckedOutroMaterial);
              }}
            />
            Outro
          </label>
          {isCheckedOutroMaterial && (
            <input 
              type="text" 
              name="materiaisEquipamentosOutro" 
              onChange={handleInputChange} 
              className={style.input} 
              placeholder="Especifique os materiais"
            />
          )}
        </div>
      </label>

      <h2 className={style.sectionTitle}>Experiência em Condições Especiais</h2>

      <label className={style.label}>
        Tem experiência em soldagem de componentes submersos em ambientes oceânicos? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="soldagemSubmersa" value="Sim, em perfuração offshore e reparo de equipamentos submersos" onChange={handleInputChange} />
            Sim, em perfuração offshore e reparo de equipamentos submersos
          </label>
          <label>
            <input type="radio" name="soldagemSubmersa" value="Tenho alguma experiência, mas com suporte de outros profissionais" onChange={handleInputChange} />
            Tenho alguma experiência, mas com suporte de outros profissionais
          </label>
          <label>
            <input type="radio" name="soldagemSubmersa" value="Não tenho experiência em soldagem submersa" onChange={handleInputChange} />
            Não tenho experiência em soldagem submersa
          </label>
        </div>
      </label>

      <label className={style.label}>
        Já trabalhou com soldagem em condições de alta pressão e temperaturas elevadas? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="altaPressao" value="Sim, com experiência significativa" onChange={handleInputChange} />
            Sim, com experiência significativa
          </label>
          <label>
            <input type="radio" name="altaPressao" value="Sim, mas com supervisão" onChange={handleInputChange} />
            Sim, mas com supervisão
          </label>
          <label>
            <input type="radio" name="altaPressao" value="Não tenho experiência em ambientes de alta pressão" onChange={handleInputChange} />
            Não tenho experiência em ambientes de alta pressão
          </label>
        </div>
      </label>

      <label className={style.label}>
        Qual é a sua experiência com soldagem em locais de difícil acesso? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="localDificilAcesso" value="Tenho ampla experiência em locais de difícil acesso" onChange={handleInputChange} />
            Tenho ampla experiência em locais de difícil acesso
          </label>
          <label>
            <input type="radio" name="localDificilAcesso" value="Tenho alguma experiência, mas com limitações" onChange={handleInputChange} />
            Tenho alguma experiência, mas com limitações
          </label>
          <label>
            <input type="radio" name="localDificilAcesso" value="Não tenho experiência em ambientes de difícil acesso" onChange={handleInputChange} />
            Não tenho experiência em ambientes de difícil acesso
          </label>
        </div>
      </label>

      <h2 className={style.sectionTitle}>Certificações e Documentos</h2>

      <label className={style.label}>
        Upload do CV (obrigatório)
        <input 
          type="file" 
          name="cvUpload" 
          onChange={handleFileChange} 
          className={style.input} 
        />
      </label>

      <label className={style.label}>
        Declaração
        <textarea 
          name="declaracao" 
          onChange={handleInputChange} 
          className={style.textarea}
          placeholder="Declare que todas as informações fornecidas são verdadeiras"
        ></textarea>
      </label>

      <div className={style.buttonGroup}>
        <Link to={'/candidaturas'}>
          <button className={style.buttonBack}>Voltar</button>
        </Link>
        <button type="button" onClick={handleSubmit} className={style.buttonNext}>
          Submeter Candidatura
        </button>
      </div>
    </div>
  );
}

export default SoldadorForm;

import { Link } from 'react-router-dom';
import img from '../../../assets/refrig4.PNG';
import style from './ChiefFinancialOfficer.module.css';

import React from 'react';

function TecnicoRefrigeracao() {
  const imgg = img;
  const titulo = 'Técnico de Refrigeração';

  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Técnico de Refrigeração para Máquinas Pesadas</h1>
          <div className={style.jobDescription}>
            <p>
            A Pathway Consulting está à procura de um Técnico de Refrigeração para Máquinas Pesadas para uma empresa do setor de petróleo e gás, com sede em Cabo Delgado. O candidato selecionado será responsável pela instalação, manutenção e reparação de sistemas de refrigeração em máquinas pesadas, assegurando o funcionamento eficiente e seguro dos equipamentos. As atribuições incluirão o diagnóstico e a resolução de problemas relacionados a falhas nos sistemas de refrigeração, bem como a execução de manutenções preventivas e corretivas. O profissional deve garantir que todos os serviços realizados estejam em conformidade com as normas de segurança e operacionais da indústria.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} alt="Técnico de Refrigeração para Máquinas Pesadas" />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Especialista na instalação, manutenção e reparação de sistemas de refrigeração e climatização em equipamentos industriais e veículos pesados.</li>
          <li>Habilidade comprovada em diagnosticar falhas em sistemas de ar condicionado e realizar reparações eficazes.</li>
          <li>Familiaridade com os sistemas de refrigeração utilizados em ambientes industriais severos e a capacidade de trabalhar sob pressão.</li>
          <li>Experiência em executar manutenções preventivas para garantir o funcionamento contínuo e eficiente dos sistemas.</li>
          <li>Conhecimento das normas de segurança e regulamentos aplicáveis ao trabalho com sistemas de refrigeração e climatização.</li>
          <li>Capacidade de colaborar com outros técnicos e engenheiros para resolver problemas complexos e otimizar operações.</li>
          <li>Formação técnica em Refrigeração, Climatização ou áreas relacionadas, complementada por certificações relevantes na área.</li>
          <li>Fluência em inglês.</li>
        </ul>
      </div>

      {/*<div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Gestão de faturação e emissão de notas de honorários.</li>
          <li>Gestão de compras e aquisições para o escritório.</li>
          <li>Controlo de contas.</li>
          <li>Gestão de contas a pagar e a receber.</li>
          <li>Reconciliações bancárias e elaboração de relatórios financeiros.</li>
          <li>Apoio na elaboração e acompanhamento de orçamentos.</li>
          <li>Garantir a conformidade com as normas financeiras e fiscais aplicáveis.</li>
        </ul>
      </div>*/}

      <div className={style.offerSection}>
        <h2 className={style.sectionTitle}>Oferecemos:</h2>
        <ul className={style.offerList}>
          <li>Ambiente de trabalho dinâmico e colaborativo.</li>
          <li>Oportunidades de desenvolvimento profissional.</li>
        </ul>
      </div>

      <div className={style.applySection}>
      <Link to={`/candidaturaspersonal/${encodeURIComponent(titulo)}`}>
          <button className={style.applyButton}>Candidatar-se</button>
        </Link>
      </div>
    </div>
  );
}

export default TecnicoRefrigeracao;
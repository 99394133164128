import { Link } from 'react-router-dom';
import img from '../../../assets/heavy.jpg';
import style from './ChiefFinancialOfficer.module.css';

import React from 'react';

function MecanicoEquipamentos() {
  const imgg = img;
  const titulo = 'Mecânico de Equipamentos Pesados';

  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Mecânico Equipamentos Pesados</h1>
          <div className={style.jobDescription}>
            <p>
            A Pathway Consulting está à procura de um Mecânico de Pesados para uma empresa do setor de petróleo e gás, com sede em Cabo Delgado. O candidato selecionado será responsável pela reparação, reconstrução, diagnóstico e resolução de problemas de equipamentos pesados hidráulicos e mecânicos, garantindo a eficiência operacional e a segurança dos equipamentos utilizados em projetos de construção e exploração.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} alt="Mecânico Equipamentos Pesados" />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Licenciatura ou qualificação técnica em Mecânica, Engenharia Mecânica ou áreas relacionadas, com experiência substancial na manutenção e reparação de equipamentos pesados.</li>
          <li>Mínimo de 5 anos de experiência na reparação e manutenção de equipamentos pesados, incluindo gruas, escavadoras e geradores, preferencialmente no setor de petróleo e gás.</li>
          <li>Conhecimento avançado em diagnóstico e resolução de problemas de sistemas hidráulicos e mecânicos.</li>
          <li>Proficiência em análise de falhas e proposta de soluções técnicas para otimizar o desempenho dos equipamentos.</li>
          <li>Experiência na operação e manutenção de equipamentos de levantamento (gruas RT, gruas de lagartas, empilhadeiras e elevadores de pessoas) e equipamentos de movimentação de terra (escavadoras, motoniveladoras, bulldozers e carregadoras).</li>
          <li>Familiaridade com ferramentas e software de manutenção, bem como manuais técnicos e esquemas.</li>
          <li>Conhecimento das normas e regulamentos de segurança no setor de petróleo e gás, incluindo práticas de saúde e segurança (ISO 45001).</li>
          <li>Fluência em inglês.</li>
        </ul>
      </div>

      {/*<div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Gestão de faturação e emissão de notas de honorários.</li>
          <li>Gestão de compras e aquisições para o escritório.</li>
          <li>Controlo de contas.</li>
          <li>Gestão de contas a pagar e a receber.</li>
          <li>Reconciliações bancárias e elaboração de relatórios financeiros.</li>
          <li>Apoio na elaboração e acompanhamento de orçamentos.</li>
          <li>Garantir a conformidade com as normas financeiras e fiscais aplicáveis.</li>
        </ul>
      </div>*/}

      <div className={style.offerSection}>
        <h2 className={style.sectionTitle}>Oferecemos:</h2>
        <ul className={style.offerList}>
          <li>Ambiente de trabalho dinâmico e colaborativo.</li>
          <li>Oportunidades de desenvolvimento profissional.</li>
        </ul>
      </div>

      <div className={style.applySection}>
      <Link to={`/candidaturaspersonal/${encodeURIComponent(titulo)}`}>
          <button className={style.applyButton}>Candidatar-se</button>
        </Link>
      </div>
    </div>
  );
}

export default MecanicoEquipamentos;
import FielInfoForm from '../layout/FielInfoForm.js';
import AssistenteForm from '../layout/AssistenteForm.js';
import SecretariaForm from '../layout/SecretariaForm.js';
import { useParams } from 'react-router-dom';
import SupervisorForm from '../layout/SupervisorForm.js';
import AutoEletricistaForm from '../layout/AutoEletricistaForm.js';
import MecanicoPesadosForm from '../layout/MecanicoPesadosForm.js';
import MecanicoVeiculosForm from '../layout/MecanicoVeiculosForm.js';
import OperadorTornoForm from '../layout/OperadorTornoForm.js';
import CanalizadorIndustrialForm from '../layout/CanalizadorIndustrialForm.js';
import TecnicoRefrigeracaoForm from '../layout/TecnicoRefrigeracaoForm.js';
import SoldadorForm from '../layout/SoldadorForm.js';


function CandidaturasPersonal() {

  const { titulo } = useParams();

  const renderComponenteVaga = () => {

    switch (decodeURIComponent(titulo).toLowerCase()) {

      case 'fiel de armazem':
        return <FielInfoForm />;
      case 'assistente administrativa e financeira':
        return <AssistenteForm />;
      case 'secretária':
        return <SecretariaForm />;
      case 'supervisor de manutenção geral':
        return <SupervisorForm />;
      case 'auto eletrecista':
        return <AutoEletricistaForm />;
      case 'mecânico de equipamentos pesados':
          return <MecanicoPesadosForm />;
      case 'mecânico de veiculos':
            return <MecanicoVeiculosForm />;
      case 'operador de torno':
            return <OperadorTornoForm />;
      case 'canalizador industrial':
            return <CanalizadorIndustrialForm />;
      case 'técnico de refrigeração':
            return <TecnicoRefrigeracaoForm />;
      case 'soldador industrial':
            return <SoldadorForm />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderComponenteVaga()}
    </>
  );
}

export default CandidaturasPersonal

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './PersonalInfoForm.module.css';
import { useParams } from 'react-router-dom';

function CandidatarPersonalForm() {
  const { titulo } = useParams();

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    position: decodeURIComponent(titulo) || '',
    firstName: '',
    lastName: '',
    birthDate: '', // Alterado de 'age' para 'birthDate'
    nationality: '',
    country: '',
    province: '',
    phone: '',
    email: '',
    linkedin: '',
    mobility: ''
  });

  const [errors, setErrors] = useState({});
  const [showMessage, setShowMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  useEffect(() => {
    let timer;
    if (showMessage) {
      timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showMessage]);

  const validateForm = () => {
    const newErrors = {};

    // Required fields validation
    Object.keys(formData).forEach((key) => {
      // Skip LinkedIn as it's optional
      if (key !== 'linkedin' && !formData[key]) {
        newErrors[key] = 'Este campo é obrigatório';
      }
    });

    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email inválido';
    }

    if (formData.phone && !/^[8][2-7][0-9]{7}$/.test(formData.phone)) {
      newErrors.phone = 'Número de telefone inválido';
    }

    if (formData.birthDate) {
      const birthDate = new Date(formData.birthDate);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - birthDate.getFullYear();
      if (age < 18 || age > 100) {
        newErrors.birthDate = 'Idade deve ser entre 18 e 100 anos';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      localStorage.setItem('formData', JSON.stringify(formData));
      console.log('Dados salvos no localStorage:', JSON.parse(localStorage.getItem('formData')));
      navigate(`/candidaturaspersonall/${encodeURIComponent(titulo)}`);
      console.log(formData);
    } else {
      setShowMessage(true);
    }
  };

  const getInputClassName = (fieldName) => {
    return `${style.input} ${errors[fieldName] ? style.errorInput : ''}`;
  };

  const getPositionDescription = () => {
    switch (formData.position) {
      case 'vaga2':
        return (
          <>
            <h3 className={style.vagaTitle}>Chief Financial Officer (CFO)</h3>
            <p>
              Welcome to the application process for the Chief Financial Officer (CFO) position at a leading company in
              the FMCG, Distribution, and Retail sector and for an NGO based in Maputo.
            </p>
            <p>
              This form is designed to gather detailed information about your experience and skills essential for the
              role, including your familiarity with financial tools and systems.
            </p>
            <p>We appreciate your interest and the time you dedicate to this application.</p> <br />
            <p>
              <i>*Only shortlisted candidates will be contacted.</i>
            </p>
          </>
        );
      case 'vaga1':
        return (
          <>
            <h3 className={style.vagaTitle}>Candidatura para a vaga de Fiel de Armazém</h3>
            <p>Estamos a contratar um(a) Fiel de Armazém para uma empresa que actua no sector de distribuição, localizada em Pemba.</p>
            <p>
              Se tens experiência em gestão de armazéns e desejas integrar uma equipa dinâmica, preenche o formulário
              abaixo para te candidatar.
            </p>
            <p>
              <i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i>
            </p>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={style.formContainer}>
      <h1 className={style.title}>Candidaturas Pathway</h1>

      {showMessage && (
        <div className={style.errorMessage}>
          Por favor, preencha todos os campos obrigatórios corretamente
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <label className={style.label}>
          Vaga Selecionada (selected position) *
          <select name="position" value={formData.position} onChange={handleChange} required>
            <option value={formData.position}>{decodeURIComponent(titulo)}</option>
          </select>
          {errors.position && <span className={style.errorText}>{errors.position}</span>}
        </label>

        <div className={style.vagaDescription}>{getPositionDescription()}</div>

        <div className={style.row}>
          <label className={style.label}>
            Nome completo *
            <input
              type="text"
              name="firstName"
              placeholder="Primeiro nome"
              value={formData.firstName}
              onChange={handleChange}
              className={getInputClassName('firstName')}
            />
            {errors.firstName && <span className={style.errorText}>{errors.firstName}</span>}
          </label>

          <label className={style.label}>
            Apelido *
            <input
              type="text"
              name="lastName"
              placeholder="Apelido"
              value={formData.lastName}
              onChange={handleChange}
              className={getInputClassName('lastName')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.lastName}</span>}
          </label>
        </div>

        <div className={style.row}>
          <label className={style.label}>
            Data de Nascimento *
            <input
              type="date"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleChange}
              className={getInputClassName('birthDate')}
            />
            {errors.birthDate && <span className={style.errorText}>{errors.birthDate}</span>}
          </label>

          <label className={style.label}>
            Nacionalidade *
            <input
              type="text"
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
              className={getInputClassName('nationality')}
            />
            {errors.nationality && <span className={style.errorText}>{errors.nationality}</span>}
          </label>
        </div>

        <div className={style.row}>
          <label className={style.label}>
            País de residência *
            <input type="text" name="country" value={formData.country} onChange={handleChange} className={getInputClassName('country')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.country}</span>}
          </label>

          <label className={style.label}>
            Província de residência *
            <input type="text" name="province" value={formData.province} onChange={handleChange} className={getInputClassName('province')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.province}</span>}
          </label>
        </div>

        <div className={style.row}>
          <label className={style.label}>
            Contacto telefónico *
            <input type="tel" name="phone" placeholder="Ex: 828384858" value={formData.phone} onChange={handleChange} className={getInputClassName('phone')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.phone}</span>}
          </label>

          <label className={style.label}>
            Endereço de email *
            <input type="email" name="email" placeholder="example@example.com" value={formData.email} onChange={handleChange} className={getInputClassName('email')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.email}</span>}
          </label>
        </div>

        <label className={style.label}>
          LinkedIn (opcional)
          <input type="url" name="linkedin" placeholder="LinkedIn profile URL" value={formData.linkedin} onChange={handleChange} />
        </label>

        <fieldset className={style.checkboxGroup}>
          <legend>Disponibilidade para trabalhar fora da província de residência (mobilidade)</legend>
          <label className={style.checkboxLabel}>
            <input type="radio" name="mobility" value="sim" onChange={handleChange} required /> Sim
          </label>
          <label className={style.checkboxLabel}>
            <input type="radio" name="mobility" value="nao" onChange={handleChange} required /> Não
          </label>
          <label className={style.checkboxLabel}>
            <input type="radio" name="mobility" value="ocasional" onChange={handleChange} required /> Disponível para deslocações ocasionais
          </label>
        </fieldset>

        <button type="submit" className={style.submitButton}>
          Next
        </button>
      </form>
    </div>
  );
}

export default CandidatarPersonalForm;

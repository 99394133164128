import { Link, useNavigate } from 'react-router-dom';
import style from './FielInfoForm.module.css';
import { useState } from 'react';

function SecretariaForm() {
    const navigate = useNavigate();

    const [isCheckedOutroTarefa, setIsCheckedOutroTarefa] = useState(false);
    const [isCheckedOutroFerramenta, setIsCheckedOutroFerramenta] = useState(false);
    const [isCheckedOutroHabilidade, setIsCheckedOutroHabilidade] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [isCheckedfera, setIsCheckedfera] = useState(false); // Adicione esta linha
    const [isCheckedeqpam, setIsCheckedeqpam] = useState(false); // Adicione esta linha

    const [formData, setFormData] = useState({
        escolaridade: '',
        instituicao: '',
        areaFormacao: '',
        experienciaAnos: '',
        tarefas: [],
        ferramentas: [],
        habilidades: [],
        fluenciaIngles: '',
        referencias: '',
        cvUpload: null,
        declaracao: '',
    });

    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: checked ? [...(prev[name] || []), value] : prev[name].filter((v) => v !== value),
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChange = (event) => {
        setFormData((prev) => ({
            ...prev,
            cvUpload: event.target.files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
          // Obter os dados do localStorage
          const previousFormData = JSON.parse(localStorage.getItem('formData')) || {};
      
          // Criar uma cópia dos dados do formulário sem o arquivo
          const formDataWithoutFile = { ...formData };
          delete formDataWithoutFile.cvUpload;
      
          // Combinar os dados
          const combinedData = {
            ...previousFormData,
            ...formDataWithoutFile,
          };
    
          // Criar um objeto FormData para envio
          const submitFormData = new FormData();
          
          // Adicionar os dados do formulário como uma string JSON
          submitFormData.append('formData', JSON.stringify(combinedData));
      
          // Adicionar o arquivo separadamente se existir
          if (formData.cvUpload) {
            submitFormData.append('cvUpload', formData.cvUpload);
          }
      
          // Enviar os dados ao backend
          const response = await fetch('https://candidaturas.pathway.co.mz/backend/adicionarcandidato.php', {
            method: 'POST',
            body: submitFormData, // Não definir Content-Type - deixe o navegador configurar automaticamente
          });
      
          const result = await response.json();
      
          if (result.success) {
            console.log('CV uploaded to:', result.cv_path);
            // Atualizar o localStorage com os dados combinados
            localStorage.setItem('formData', JSON.stringify(combinedData));
            navigate('/');
            alert("Candidatura Submetida com sucesso");
          } else {
            console.error('Erro ao salvar:', result.message);
            alert('Erro ao salvar os dados. Por favor, tente novamente.');
          }
        } catch (error) {
          console.error('Erro:', error);
          alert('Erro ao salvar os dados. Por favor, tente novamente.');
        }
      };

    return (
        <div className={style.formContainer}>
            <h2 className={style.sectionTitle}>Formação Acadêmica</h2>

            <label className={style.label}>
                Qual é o teu nível de escolaridade *
                <select name="escolaridade" onChange={handleInputChange} className={style.select}>
                    <option value="">Selecione</option>
                    <option value="Ensino Médio completo">Ensino Médio completo</option>
                    <option value="Formação Técnica">Formação Técnica</option>
                    <option value="Superior">Superior (Licenciatura)</option>
                </select>
            </label>

            <label className={style.label}>
                Qual é a tua instituição de ensino
                <input type="text" name="instituicao" onChange={handleInputChange} className={style.input} />
            </label>

            <label className={style.label}>
                Em que área é a tua formação *
                <div className={style.radioGroup}>
                    <label>
                        <input type="radio" name="areaFormacao" value="Administração" onChange={handleInputChange} />
                        Administração
                    </label>
                    <label>
                        <input type="radio" name="areaFormacao" value="Contabilidade" onChange={handleInputChange} />
                        Contabilidade
                    </label>
                    <label>
                        <input type="radio" name="areaFormacao" value="Gestão Financeira" onChange={handleInputChange} />
                        Gestão Financeira
                    </label>
                    <label>
                        <input type="radio" name="areaFormacao" value="Outro" onChange={(e) => { handleInputChange(e); setSelectedOption(e.target.value); }} />
                        Outro (Especificar)
                    </label>
                    {selectedOption === 'Outro' && (
                        <label className={style.label}>
                            Especifique a formação
                            <input type="text" name="areaFormacaoOutro" onChange={handleInputChange} className={style.input} />
                        </label>
                    )}
                </div>
            </label>

            <h2 className={style.sectionTitle}>Experiência e Conhecimentos Técnicos</h2>

            <label className={style.label}>
                Quantos anos de experiência tem em funções administrativas *
                <div className={style.radioGroup}>
                    <label>
                        <input type="radio" name="experienciaAnos" value="Menos de 1 ano" onChange={handleInputChange} /> Menos de 1 ano
                    </label>
                    <label>
                        <input type="radio" name="experienciaAnos" value="Entre 1 e 3 anos" onChange={handleInputChange} /> Entre 1 e 3 anos
                    </label>
                    <label>
                        <input type="radio" name="experienciaAnos" value="Mais de 3 anos" onChange={handleInputChange} /> Mais de 3 anos
                    </label>
                </div>
            </label>

            <label className={style.label}>
                Quais das seguintes tarefas realizou em funções anteriores *
                <div className={style.checkboxGroup}>
                    <label>
                        <input type="checkbox" name="tarefas" value="Gestão de agendas" onChange={handleCheckboxChange} /> Gestão de agendas
                    </label>
                    <label>
                        <input type="checkbox" name="tarefas" value="Organização de reuniões" onChange={handleCheckboxChange} /> Organização de reuniões
                    </label>
                    <label>
                        <input type="checkbox" name="tarefas" value="Atendimento ao público" onChange={handleCheckboxChange} /> Atendimento ao público
                    </label>
                    <label>
                        <input type="checkbox" name="tarefas" value="Outro" onChange={(e) => { handleCheckboxChange(e); setIsCheckedOutroTarefa(!isCheckedOutroTarefa); }} /> Outro (especificar)
                    </label>
                    {isCheckedOutroTarefa && (
                        <label className={style.label}>
                            Especifique a tarefa
                            <input type="text" name="tarefasOutro" onChange={handleInputChange} className={style.input} />
                        </label>
                    )}
                </div>
            </label>

            <label className={style.label}>
                Quais das seguintes ferramentas ou sistemas de gestão de inventário utilizou *
                <div className={style.checkboxGroup}>
                    <label>
                        <input type="checkbox" name="ferramentas" value="ERP PHC" onChange={handleCheckboxChange} /> ERP PHC
                    </label>
                    <label>
                        <input type="checkbox" name="ferramentas" value="Excel" onChange={handleCheckboxChange} /> Excel
                    </label>
                    <label>
                        <input type="checkbox" name="ferramentas" value="Outro" onChange={(e) => { handleCheckboxChange(e); setIsCheckedfera(!isCheckedfera); }} /> Outro (especificar)
                    </label>
                    {isCheckedfera && <label className={style.label}>
                        Especifique a ferramenta
                        <input type="text" name="ferramentasOutro" onChange={handleInputChange} className={style.input} />
                    </label>}
                </div>
            </label>

            <h2 className={style.sectionTitle}>Referências e Documentos Adicionais</h2>

            <label className={style.label}>
                Referências (opcional)
                <textarea name="referencias" onChange={handleInputChange} className={style.textarea}></textarea>
            </label>

            <label className={style.label}>
                Upload do CV (obrigatório)
                <input type="file" name="cvUpload" onChange={handleFileChange} className={style.input} />
            </label>

            <label className={style.label}>
                Declaração de Compromisso
                <textarea name="declaracao" onChange={handleInputChange} className={style.textarea}></textarea>
            </label>

            <div className={style.buttonGroup}>
                <Link to={'/candidaturas'}>
                    <button className={style.buttonBack}>Voltar</button>
                </Link>
                <button type="button" onClick={handleSubmit} className={style.buttonNext}>Seguinte</button>
            </div>
        </div>
    );
}

export default SecretariaForm;

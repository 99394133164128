import { Link, useNavigate } from 'react-router-dom';
import style from './FielInfoForm.module.css';
import { useState, useEffect } from 'react';

function ManutencaoForm() {
  const navigate = useNavigate();
  const [previousFormData, setPreviousFormData] = useState(null);
  const [isCheckedOutroSetor, setIsCheckedOutroSetor] = useState(false);
  const [isCheckedOutraFerramenta, setIsCheckedOutraFerramenta] = useState(false);

  const [formData, setFormData] = useState({
    experienciaAnos: '',
    setores: [],
    setoresOutro: '',
    ferramentas: [],
    ferramentasOutro: '',
    indicadoresDesempenho: '',
    projetosManutencao: '',
    referencias: '',
    cvUpload: null,
    declaracao: '',
    fluenciaIngles: ''
  });

  useEffect(() => {
    const savedData = localStorage.getItem('candidatarPersonalFormData');
    if (savedData) {
      setPreviousFormData(JSON.parse(savedData));
    }
  }, []);

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    
    if (!checked && value === "Outros") {
      setFormData(prev => ({
        ...prev,
        [name]: prev[name].filter(v => v !== "Outros"),
        [`${name}Outro`]: ''
      }));
      return;
    }

    setFormData(prev => ({
      ...prev,
      [name]: checked
        ? [...(prev[name] || []), value]
        : prev[name].filter(v => v !== value),
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData(prev => ({
      ...prev,
      cvUpload: event.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const previousFormData = JSON.parse(localStorage.getItem('formData')) || {};
  
      const processedFormData = {
        ...formData,
        setores: formData.setores.map(s => s === 'Outros' ? formData.setoresOutro : s).filter(s => s),
        ferramentas: formData.ferramentas.map(f => f === 'Outros' ? formData.ferramentasOutro : f).filter(f => f)
      };

      const formDataWithoutFile = { ...processedFormData };
      delete formDataWithoutFile.cvUpload;
  
      const combinedData = {
        ...previousFormData,
        ...formDataWithoutFile,
      };

      const submitFormData = new FormData();
      submitFormData.append('formData', JSON.stringify(combinedData));
  
      if (formData.cvUpload) {
        submitFormData.append('cvUpload', formData.cvUpload);
      }
  
      const response = await fetch('https://candidaturas.pathway.co.mz/backend/adicionarcandidato.php', {
        method: 'POST',
        body: submitFormData,
      });
  
      const result = await response.json();
  
      if (result.success) {
        console.log('CV uploaded to:', result.cv_path);
        localStorage.setItem('formData', JSON.stringify(combinedData));
        navigate('/');
        alert("Candidatura Submetida com sucesso");
      } else {
        console.error('Erro ao salvar:', result.message);
        alert('Erro ao salvar os dados. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao salvar os dados. Por favor, tente novamente.');
    }
  };

  return (
    <div className={style.formContainer}>
      <h2 className={style.sectionTitle}>Experiência Profissional</h2>
      
      <label className={style.label}>
        Quantos anos de experiência tem na supervisão de equipas de manutenção? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="experienciaAnos" value="Menos de 2 anos" onChange={handleInputChange} />
            Menos de 2 anos
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="2 a 5 anos" onChange={handleInputChange} />
            2 a 5 anos
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="Mais de 5 anos" onChange={handleInputChange} />
            Mais de 5 anos
          </label>
        </div>
      </label>

      <label className={style.label}>
        Já trabalhou em quais sectores? *
        <div className={style.checkboxGroup}>
          <label>
            <input type="checkbox" name="setores" value="Mineração" onChange={handleCheckboxChange} />
            Mineração
          </label>
          <label>
            <input type="checkbox" name="setores" value="Energia" onChange={handleCheckboxChange} />
            Energia
          </label>
          <label>
            <input type="checkbox" name="setores" value="Oil & Gas" onChange={handleCheckboxChange} />
            Oil & Gas
          </label>
          <label>
            <input type="checkbox" name="setores" value="Metalurgia" onChange={handleCheckboxChange} />
            Metalurgia
          </label>
          <label>
            <input 
              type="checkbox" 
              name="setores" 
              value="Outros" 
              onChange={(e) => {
                handleCheckboxChange(e);
                setIsCheckedOutroSetor(!isCheckedOutroSetor);
              }}
            />
            Outros (especificar)
          </label>
          {isCheckedOutroSetor && (
            <label className={style.label}>
              Especifique o setor
              <input type="text" name="setoresOutro" onChange={handleInputChange} className={style.input} />
            </label>
          )}
        </div>
      </label>

      <label className={style.label}>
        Quais ferramentas de gestão de manutenção já utilizou? *
        <div className={style.checkboxGroup}>
          <label>
            <input type="checkbox" name="ferramentas" value="SAP PM" onChange={handleCheckboxChange} />
            SAP PM
          </label>
          <label>
            <input type="checkbox" name="ferramentas" value="Maximo" onChange={handleCheckboxChange} />
            Maximo
          </label>
          <label>
            <input type="checkbox" name="ferramentas" value="ManWinWin" onChange={handleCheckboxChange} />
            ManWinWin
          </label>
          <label>
            <input 
              type="checkbox" 
              name="ferramentas" 
              value="Outros" 
              onChange={(e) => {
                handleCheckboxChange(e);
                setIsCheckedOutraFerramenta(!isCheckedOutraFerramenta);
              }}
            />
            Outros (especificar)
          </label>
          {isCheckedOutraFerramenta && (
            <label className={style.label}>
              Especifique a ferramenta
              <input type="text" name="ferramentasOutro" onChange={handleInputChange} className={style.input} />
            </label>
          )}
        </div>
      </label>

      <label className={style.label}>
        Está habituado a trabalhar com indicadores de desempenho? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="indicadoresDesempenho" value="Sim" onChange={handleInputChange} />
            Sim
          </label>
          <label>
            <input type="radio" name="indicadoresDesempenho" value="Não" onChange={handleInputChange} />
            Não
          </label>
        </div>
      </label>

      <label className={style.label}>
        Já liderou projectos de manutenção corretiva em equipamentos críticos? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="projetosManutencao" value="Sim, frequentemente" onChange={handleInputChange} />
            Sim, frequentemente
          </label>
          <label>
            <input type="radio" name="projetosManutencao" value="Sim, mas raramente" onChange={handleInputChange} />
            Sim, mas raramente
          </label>
          <label>
            <input type="radio" name="projetosManutencao" value="Não" onChange={handleInputChange} />
            Não
          </label>
        </div>
      </label>

      <label className={style.label}>
  Como avalia a sua fluência em inglês? *
  <div className={style.radioGroup}>
    <label>
      <input 
        type="radio" 
        name="fluenciaIngles" 
        value="Básico" 
        onChange={handleInputChange} 
      />
      Básico
    </label>
    <label>
      <input 
        type="radio" 
        name="fluenciaIngles" 
        value="Intermediário" 
        onChange={handleInputChange} 
      />
      Intermediário
    </label>
    <label>
      <input 
        type="radio" 
        name="fluenciaIngles" 
        value="Avançado" 
        onChange={handleInputChange} 
      />
      Avançado
    </label>
    <label>
      <input 
        type="radio" 
        name="fluenciaIngles" 
        value="Fluente" 
        onChange={handleInputChange} 
      />
      Fluente
    </label>
  </div>
</label>

      <h2 className={style.sectionTitle}>Referências e Documentos Adicionais</h2>

      <label className={style.label}>
        Referências (opcional)
        <textarea name="referencias" onChange={handleInputChange} className={style.textarea}></textarea>
      </label>

      <label className={style.label}>
        Upload do CV (obrigatório)
        <input type="file" name="cvUpload" onChange={handleFileChange} className={style.input} />
      </label>

      <label className={style.label}>
        Declaração de Compromisso
        <textarea name="declaracao" onChange={handleInputChange} className={style.textarea}></textarea>
      </label>

      <div className={style.buttonGroup}>
        <Link to={'/candidaturas'}>
          <button className={style.buttonBack}>Voltar</button>
        </Link>
        <button type="button" onClick={handleSubmit} className={style.buttonNext}>
          Seguinte
        </button>
      </div>
    </div>
  );
}

export default ManutencaoForm;
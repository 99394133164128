import { Link, useNavigate } from 'react-router-dom';
import style from './FielInfoForm.module.css';
import { useState, useEffect } from 'react';

function CanalizadorIndustrialForm() {
    const navigate = useNavigate();
    const [previousFormData, setPreviousFormData] = useState(null);
    const [isCheckedOutroMaterial, setIsCheckedOutroMaterial] = useState(false);
    const [isCheckedOutroDiagnostico, setIsCheckedOutroDiagnostico] = useState(false);

    const [formData, setFormData] = useState({
        experienciaAnos: '',
        tubulacoesAltaPressao: '',
        materiaisExperiencia: '',
        diagnosticoVazamentos: '',
        diagnosticoOutro: '',
        materiaisManuseados: [],
        materiaisOutro: '',
        sistemasHidraulicos: '',
        projetosGrandePorte: '',
        referencias: '',
        cvUpload: null,
        declaracao: ''
    });

    useEffect(() => {
        const savedData = localStorage.getItem('candidatarPersonalFormData');
        if (savedData) {
            setPreviousFormData(JSON.parse(savedData));
        }
    }, []);

    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;

        if (!checked && value === "Outros") {
            setFormData(prev => ({
                ...prev,
                [name]: prev[name].filter(v => v !== "Outros"),
                [`${name}Outro`]: ''
            }));
            return;
        }

        setFormData(prev => ({
            ...prev,
            [name]: checked
                ? [...(prev[name] || []), value]
                : prev[name].filter(v => v !== value),
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChange = (event) => {
        setFormData(prev => ({
            ...prev,
            cvUpload: event.target.files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const previousFormData = JSON.parse(localStorage.getItem('formData')) || {};

            const processedDiagnostico = formData.diagnosticoVazamentos === "Outro"
                ? formData.diagnosticoOutro
                : formData.diagnosticoVazamentos;

            const processedFormData = {
                ...formData,
                diagnosticoVazamentos: processedDiagnostico,
                materiaisManuseados: formData.materiaisManuseados.map(m =>
                    m === 'Outros' ? formData.materiaisOutro : m
                ).filter(m => m)
            };

            delete processedFormData.diagnosticoOutro;
            delete processedFormData.materiaisOutro;
            const formDataWithoutFile = { ...processedFormData };
            delete formDataWithoutFile.cvUpload;

            const combinedData = {
                ...previousFormData,
                ...formDataWithoutFile,
            };

            const submitFormData = new FormData();
            submitFormData.append('formData', JSON.stringify(combinedData));

            if (formData.cvUpload) {
                submitFormData.append('cvUpload', formData.cvUpload);
            }

            const response = await fetch('https://candidaturas.pathway.co.mz/backend/adicionarcandidato.php', {
                method: 'POST',
                body: submitFormData,
            });

            const result = await response.json();

            if (result.success) {
                console.log('CV uploaded to:', result.cv_path);
                localStorage.setItem('formData', JSON.stringify(combinedData));
                navigate('/');
                alert("Candidatura Submetida com sucesso");
            } else {
                console.error('Erro ao salvar:', result.message);
                alert('Erro ao salvar os dados. Por favor, tente novamente.');
            }
        } catch (error) {
            console.error('Erro:', error);
            alert('Erro ao salvar os dados. Por favor, tente novamente.');
        }
    };

    return (
        <div className={style.formContainer}>
            <h2 className={style.sectionTitle}>Experiência Profissional</h2>

            <label className={style.label}>
                Quantos anos de experiência tem como canalizador industrial? *
                <div className={style.radioGroup}>
                    <label>
                        <input type="radio" name="experienciaAnos" value="Menos de 1 ano" onChange={handleInputChange} />
                        Menos de 1 ano
                    </label>
                    <label>
                        <input type="radio" name="experienciaAnos" value="1 a 3 anos" onChange={handleInputChange} />
                        1 a 3 anos
                    </label>
                    <label>
                        <input type="radio" name="experienciaAnos" value="Mais de 3 anos" onChange={handleInputChange} />
                        Mais de 3 anos
                    </label>
                </div>
            </label>

            <label className={style.label}>
                Já trabalhou com tubulações de alta pressão? *
                <div className={style.radioGroup}>
                    <label>
                        <input type="radio" name="tubulacoesAltaPressao" value="Sim" onChange={handleInputChange} />
                        Sim
                    </label>
                    <label>
                        <input type="radio" name="tubulacoesAltaPressao" value="Não" onChange={handleInputChange} />
                        Não
                    </label>
                </div>
            </label>

            <label className={style.label}>
                Quais materiais você tem experiência em manusear e instalar em sistemas de tubulação industrial? *
                <div className={style.radioGroup}>
                    <label>
                        <input type="radio" name="materiaisExperiencia" value="Aço, cobre, PVC e PEX" onChange={handleInputChange} />
                        Aço, cobre, PVC e PEX
                    </label>
                    <label>
                        <input type="radio" name="materiaisExperiencia" value="Aço e cobre" onChange={handleInputChange} />
                        Aço e cobre
                    </label>
                    <label>
                        <input type="radio" name="materiaisExperiencia" value="Apenas PVC" onChange={handleInputChange} />
                        Apenas PVC
                    </label>
                    <label>
                        <input type="radio" name="materiaisExperiencia" value="Não tenho experiência com materiais industriais" onChange={handleInputChange} />
                        Não tenho experiência com materiais industriais
                    </label>
                    <label>
                        <input type="radio" name="materiaisExperiencia" value="Outro" onChange={handleInputChange} />
                        Outro
                    </label>
                </div>
            </label>

            <label className={style.label}>
                Já realizou diagnóstico e a reparação de vazamentos em tubulações industriais? *
                <div className={style.radioGroup}>
                    <label>
                        <input type="radio" name="diagnosticoVazamentos" value="Inspeção visual e testes de pressão" onChange={handleInputChange} />
                        Inspeção visual e testes de pressão
                    </label>
                    <label>
                        <input type="radio" name="diagnosticoVazamentos" value="Utilização de métodos como ultrassom e câmeras de inspeção" onChange={handleInputChange} />
                        Utilização de métodos como ultrassom e câmeras de inspeção
                    </label>
                    <label>
                        <input type="radio" name="diagnosticoVazamentos" value="Substituição das seções danificadas sem inspeção detalhada" onChange={handleInputChange} />
                        Substituição das seções danificadas sem inspeção detalhada
                    </label>
                    <label>
                        <input type="radio" name="diagnosticoVazamentos" value="Não tenho experiência em diagnóstico de vazamentos" onChange={handleInputChange} />
                        Não tenho experiência em diagnóstico de vazamentos
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="diagnosticoVazamentos"
                            value="Outro"
                            onChange={(e) => {
                                handleInputChange(e);
                                setIsCheckedOutroDiagnostico(!isCheckedOutroDiagnostico);
                            }}
                        />
                        Outro
                    </label>
                    {isCheckedOutroDiagnostico && (
                        <label className={style.label}>
                            Especifique outro método de diagnóstico
                            <input type="text" name="diagnosticoOutro" onChange={handleInputChange} className={style.input} />
                        </label>
                    )}
                </div>
            </label>

            <label className={style.label}>
                Quais materiais já manuseou? *
                <div className={style.checkboxGroup}>
                    <label>
                        <input type="checkbox" name="materiaisManuseados" value="Aço" onChange={handleCheckboxChange} />
                        Aço
                    </label>
                    <label>
                        <input type="checkbox" name="materiaisManuseados" value="Cobre" onChange={handleCheckboxChange} />
                        Cobre
                    </label>
                    <label>
                        <input type="checkbox" name="materiaisManuseados" value="PVC" onChange={handleCheckboxChange} />
                        PVC
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="materiaisManuseados"
                            value="Outros"
                            onChange={(e) => {
                                handleCheckboxChange(e);
                                setIsCheckedOutroMaterial(!isCheckedOutroMaterial);
                            }}
                        />
                        Outros
                    </label>
                    {isCheckedOutroMaterial && (
                        <label className={style.label}>
                            Especifique outros materiais
                            <input type="text" name="materiaisOutro" onChange={handleInputChange} className={style.input} />
                        </label>
                    )}
                </div>
            </label>

            <label className={style.label}>
                Tem experiência com sistemas hidráulicos industriais? *
                <div className={style.radioGroup}>
                    <label>
                        <input type="radio" name="sistemasHidraulicos" value="Sim" onChange={handleInputChange} />
                        Sim
                    </label>
                    <label>
                        <input type="radio" name="sistemasHidraulicos" value="Não" onChange={handleInputChange} />
                        Não
                    </label>
                </div>
            </label>

            <label className={style.label}>
                Já trabalhou em projetos industriais de grande porte? *
                <div className={style.radioGroup}>
                    <label>
                        <input type="radio" name="projetosGrandePorte" value="Sim" onChange={handleInputChange} />
                        Sim
                    </label>
                    <label>
                        <input type="radio" name="projetosGrandePorte" value="Não" onChange={handleInputChange} />
                        Não
                    </label>
                </div>
            </label>

            <h2 className={style.sectionTitle}>Referências e Documentos Adicionais</h2>

            <label className={style.label}>
                Referências (opcional)
                <textarea name="referencias" onChange={handleInputChange} className={style.textarea}></textarea>
            </label>

            <label className={style.label}>
                Upload do CV (obrigatório)
                <input type="file" name="cvUpload" onChange={handleFileChange} className={style.input} />
            </label>

            <label className={style.label}>
                Declaração de Compromisso
                <textarea name="declaracao" onChange={handleInputChange} className={style.textarea}></textarea>
            </label>

            <div className={style.buttonGroup}>
                <Link to={'/candidaturas'}>
                    <button className={style.buttonBack}>Voltar</button>
                </Link>
                <button type="button" onClick={handleSubmit} className={style.buttonNext}>
                    Seguinte
                </button>
            </div>
        </div>
    );
}

export default CanalizadorIndustrialForm;
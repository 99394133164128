import { Link } from 'react-router-dom';
import img from '../../../assets/Eletrician.PNG';
import style from './ChiefFinancialOfficer.module.css';

import React from 'react';

function AutoEletrecista() {
  const imgg = img;
  const titulo = 'Auto Eletrecista';

  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Auto Eletrecista</h1>
          <div className={style.jobDescription}>
            <p>
            A Pathway Consulting está à procura de um Eletricista Automóvel para uma empresa do setor de petróleo e gás, com sede em Cabo Delgado. O candidato selecionado será responsável pela reparação, diagnóstico e resolução de problemas de sistemas elétricos e eletrónicos em equipamentos e máquinas.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} alt="Auto Eletrecista" />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Licenciatura ou qualificação técnica em Engenharia Elétrica, Eletrónica ou áreas relacionadas, com experiência significativa na manutenção e reparação de sistemas elétricos.</li>
          <li>Mínimo de 5 anos de experiência em diagnóstico e resolução de problemas de sistemas elétricos e eletrónicos em equipamentos pesados, incluindo: Equipamentos de Levantamento; Equipamentos de Movimentação de Terra; Veículos de Transporte de Pessoal; Veículos de Transporte de Mercadorias e Geradores (LV & MV)</li>
          <li>Proficiência na interpretação de manuais técnicos, desenhos esquemáticos e manuais de peças.</li>
          <li>Capacidade de propor as peças elétricas e consumíveis necessários para o equipamento.</li>
          <li>Familiaridade com ferramentas e software de diagnóstico utilizados na resolução de problemas elétricos.</li>
          <li>Conhecimento das normas e regulamentos de segurança no setor de petróleo e gás, incluindo práticas de saúde e segurança (ISO 45001).</li>
          <li>Fluência em inglês.</li>
        </ul>
      </div>

      {/*<div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Gestão de faturação e emissão de notas de honorários.</li>
          <li>Gestão de compras e aquisições para o escritório.</li>
          <li>Controlo de contas.</li>
          <li>Gestão de contas a pagar e a receber.</li>
          <li>Reconciliações bancárias e elaboração de relatórios financeiros.</li>
          <li>Apoio na elaboração e acompanhamento de orçamentos.</li>
          <li>Garantir a conformidade com as normas financeiras e fiscais aplicáveis.</li>
        </ul>
      </div>*/}

      <div className={style.offerSection}>
        <h2 className={style.sectionTitle}>Oferecemos:</h2>
        <ul className={style.offerList}>
          <li>Ambiente de trabalho dinâmico e colaborativo.</li>
          <li>Oportunidades de desenvolvimento profissional.</li>
        </ul>
      </div>

      <div className={style.applySection}>
      <Link to={`/candidaturaspersonal/${encodeURIComponent(titulo)}`}>
          <button className={style.applyButton}>Candidatar-se</button>
        </Link>
      </div>
    </div>
  );
}

export default AutoEletrecista;
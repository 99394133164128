import { useParams } from 'react-router-dom';
import GerentedeProjectos from '../layout/vagas/GerentedeProjectos.js';
// import RepresentantedeVendas from '../layout/vagas/RepresentantedeVendas.js';
// import FieldeArmazem from '../layout/vagas/FieldeArmazem.js';
// import ChiefFinancialOfficer from '../layout/vagas/ChiefFinancialOfficer.js';
// import KeyAccountManager from '../layout/vagas/KeyAccountManager.js';
import ProcurementOfficer from '../layout/vagas/ProcurementOfficer.js';
import Secretaria from '../layout/vagas/Secretaria.js';
import AssistenteAdministrativa from '../layout/vagas/AssistenteAdministrativa.js';
import AutoEletricista from '../layout/vagas/AutoEletrecista.js'
import MecanicoEquipamentos from '../layout/vagas/MecanicoEquipamentos.js';
import MecanicoVeiculos from '../layout/vagas/MecanicoVeiculos.js';
import OperadorTorno from '../layout/vagas/OperadorTorno.js';
import CanalizadorIndustrial from '../layout/vagas/CanalizadorIndustrial.js'
import TecnicoRefrigeracao from '../layout/vagas/TecnicoRefrigeracao.js'
import SoldadorIndustrial from '../layout/vagas/SoldadorIndustrial.js';

function VagaDescription() {
  const { titulo } = useParams();

  const renderComponenteVaga = () => {
    switch (decodeURIComponent(titulo).toLowerCase()) {
      case 'supervisor de manutenção geral':
        return <GerentedeProjectos />;
      case 'auto eletricista':
        return <AutoEletricista />;
      case 'mecânico de equipamentos pesados':
        return <MecanicoEquipamentos />;
      case 'mecânico de veiculos':
        return <MecanicoVeiculos />;
      case 'operador de torno':
        return <OperadorTorno />;
      case 'canalizador industrial':
        return <CanalizadorIndustrial />;
      case 'secretária':
        return <Secretaria />;
      case 'assistente administrativa e financeira':
        return <AssistenteAdministrativa />;
      case 'técnico de refrigeração':
          return <TecnicoRefrigeracao />;
      case 'soldador industrial':
          return <SoldadorIndustrial />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderComponenteVaga()}
    </>
  );
}

export default VagaDescription;
import { Link } from 'react-router-dom';
import img from '../../../assets/plumber.PNG';
import style from './ChiefFinancialOfficer.module.css';

import React from 'react';

function CanalizadorIndustrial() {
  const imgg = img;
  const titulo = 'Canalizador Industrial';

  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Canalizador Industrial</h1>
          <div className={style.jobDescription}>
            <p>
            A Pathway Consulting está à procura de um Canalizador Industrial para uma empresa do setor de petróleo e gás, com sede em Cabo Delgado. O candidato selecionado será responsável pela instalação, manutenção e reparação de sistemas de tubulações e equipamentos industriais. Suas funções incluirão o diagnóstico e a resolução de problemas relacionados a vazamentos e falhas nos sistemas de encanamento, garantindo a eficiência e a segurança das operações. O profissional deverá assegurar que todas as instalações atendam às normas e regulamentos de segurança aplicáveis.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} alt="Operador de Torno" />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Experiência comprovada em montagem, manutenção e reparação de sistemas de tubulação industrial para equipamentos pesados.</li>
          <li>Conhecimento avançado em leitura e interpretação de esquemas hidráulicos e diagramas de tubulação.</li>
          <li>Habilidade na seleção de materiais adequados para sistemas de alta pressão, garantindo a durabilidade e segurança das instalações.</li>
          <li>Capacidade de identificar e resolver problemas relacionados a sistemas de tubulação e encanamento.</li>
          <li>Conhecimento das normas de segurança e regulamentos aplicáveis ao trabalho em tubulações industriais.</li>
          <li>Capacidade de colaborar efetivamente com outros membros da equipe de produção e engenharia.</li>
          <li>Experiência em realizar manutenções preventivas e corretivas em sistemas de tubulação para minimizar paradas operacionais.</li>
          <li>Fluência em inglês.</li>
        </ul>
      </div>

      {/*<div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Gestão de faturação e emissão de notas de honorários.</li>
          <li>Gestão de compras e aquisições para o escritório.</li>
          <li>Controlo de contas.</li>
          <li>Gestão de contas a pagar e a receber.</li>
          <li>Reconciliações bancárias e elaboração de relatórios financeiros.</li>
          <li>Apoio na elaboração e acompanhamento de orçamentos.</li>
          <li>Garantir a conformidade com as normas financeiras e fiscais aplicáveis.</li>
        </ul>
      </div>*/}

      <div className={style.offerSection}>
        <h2 className={style.sectionTitle}>Oferecemos:</h2>
        <ul className={style.offerList}>
          <li>Ambiente de trabalho dinâmico e colaborativo.</li>
          <li>Oportunidades de desenvolvimento profissional.</li>
        </ul>
      </div>

      <div className={style.applySection}>
      <Link to={`/candidaturaspersonal/${encodeURIComponent(titulo)}`}>
          <button className={style.applyButton}>Candidatar-se</button>
        </Link>
      </div>
    </div>
  );
}

export default CanalizadorIndustrial;
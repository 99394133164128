import { Link, useNavigate } from 'react-router-dom';
import style from './FielInfoForm.module.css';
import { useState, useEffect } from 'react';

function OperadorTornoForm() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    experienciaAnos: '',
    experienciaCNC: '',
    toleranciasRigorosas: '',
    manutencaoEletrica: '',
    manutencaoEletricaOutro: '',
    desenhosTecnicos: '',
    referencias: '',
    cvUpload: null,
    declaracao: ''
  });

  const [isCheckedOutroManutencao, setIsCheckedOutroManutencao] = useState(false);

  useEffect(() => {
    const savedData = localStorage.getItem('candidatarPersonalFormData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData(prev => ({
      ...prev,
      cvUpload: event.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const previousFormData = JSON.parse(localStorage.getItem('formData')) || {};
  
      const processedFormData = {
        ...formData,
        manutencaoEletrica: formData.manutencaoEletrica === 'Outro' ? 
          formData.manutencaoEletricaOutro : formData.manutencaoEletrica,
      };

      const formDataWithoutFile = { ...processedFormData };
      delete formDataWithoutFile.cvUpload;
  
      const combinedData = {
        ...previousFormData,
        ...formDataWithoutFile,
      };

      const submitFormData = new FormData();
      submitFormData.append('formData', JSON.stringify(combinedData));
  
      if (formData.cvUpload) {
        submitFormData.append('cvUpload', formData.cvUpload);
      }
  
      const response = await fetch('https://candidaturas.pathway.co.mz/backend/adicionarcandidato.php', {
        method: 'POST',
        body: submitFormData,
      });
  
      const result = await response.json();
  
      if (result.success) {
        console.log('CV uploaded to:', result.cv_path);
        localStorage.setItem('formData', JSON.stringify(combinedData));
        navigate('/');
        alert("Candidatura Submetida com sucesso");
      } else {
        console.error('Erro ao salvar:', result.message);
        alert('Erro ao salvar os dados. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao salvar os dados. Por favor, tente novamente.');
    }
  };
  
  return (
    <div className={style.formContainer}>
      <h2 className={style.sectionTitle}>Experiência em Operação de Torno</h2>

      <label className={style.label}>
        Quantos anos de experiência tem em operação de tornos? *
        <div className={style.radioGroup}>
          <label>
            <input 
              type="radio" 
              name="experienciaAnos" 
              value="Menos de 1 ano" 
              onChange={handleInputChange} 
            /> 
            Menos de 1 ano
          </label>
          <label>
            <input 
              type="radio" 
              name="experienciaAnos" 
              value="Entre 1 e 3 anos" 
              onChange={handleInputChange} 
            /> 
            Entre 1 e 3 anos
          </label>
          <label>
            <input 
              type="radio" 
              name="experienciaAnos" 
              value="Mais de 3 anos" 
              onChange={handleInputChange} 
            /> 
            Mais de 3 anos
          </label>
        </div>
      </label>

      <label className={style.label}>
        Tem experiência em operar tornos CNC no setor industrial? *
        <div className={style.radioGroup}>
          <label>
            <input 
              type="radio" 
              name="experienciaCNC" 
              value="Sim, em diversos setores industriais" 
              onChange={handleInputChange} 
            /> 
            Sim, em diversos setores industriais
          </label>
          <label>
            <input 
              type="radio" 
              name="experienciaCNC" 
              value="Sim, apenas em setores não industriais" 
              onChange={handleInputChange} 
            /> 
            Sim, apenas em setores não industriais
          </label>
          <label>
            <input 
              type="radio" 
              name="experienciaCNC" 
              value="Não, mas tenho interesse em aprender" 
              onChange={handleInputChange} 
            /> 
            Não, mas tenho interesse em aprender
          </label>
          <label>
            <input 
              type="radio" 
              name="experienciaCNC" 
              value="Não tenho experiência em CNC" 
              onChange={handleInputChange} 
            /> 
            Não tenho experiência em CNC
          </label>
        </div>
      </label>

      <label className={style.label}>
        Está habituado a usinar metais com tolerâncias rigorosas? *
        <div className={style.radioGroup}>
          <label>
            <input 
              type="radio" 
              name="toleranciasRigorosas" 
              value="Sim" 
              onChange={handleInputChange} 
            /> 
            Sim
          </label>
          <label>
            <input 
              type="radio" 
              name="toleranciasRigorosas" 
              value="Não" 
              onChange={handleInputChange} 
            /> 
            Não
          </label>
        </div>
      </label>

      <label className={style.label}>
        Já realizou manutenção elétrica em equipamentos industriais, como motores de torno? *
        <div className={style.radioGroup}>
          <label>
            <input 
              type="radio" 
              name="manutencaoEletrica" 
              value="Diagnóstico e reparo de falhas elétricas" 
              onChange={handleInputChange} 
            /> 
            Diagnóstico e reparo de falhas elétricas
          </label>
          <label>
            <input 
              type="radio" 
              name="manutencaoEletrica" 
              value="Troca de componentes básicos" 
              onChange={handleInputChange} 
            /> 
            Troca de componentes básicos (fusíveis, cabos, etc.)
          </label>
          <label>
            <input 
              type="radio" 
              name="manutencaoEletrica" 
              value="Não realizo manutenção elétrica" 
              onChange={handleInputChange} 
            /> 
            Não realizo manutenção elétrica
          </label>
          <label>
            <input 
              type="radio" 
              name="manutencaoEletrica" 
              value="Outro" 
              onChange={(e) => {
                handleInputChange(e);
                setIsCheckedOutroManutencao(!isCheckedOutroManutencao);
              }} 
            /> 
            Outro (especificar)
          </label>
          {isCheckedOutroManutencao && (
            <label className={style.label}>
              Especifique o tipo de manutenção
              <input 
                type="text" 
                name="manutencaoEletricaOutro" 
                onChange={handleInputChange} 
                className={style.input} 
              />
            </label>
          )}
        </div>
      </label>

      <label className={style.label}>
        Tem experiência em leitura de desenhos técnicos? *
        <div className={style.radioGroup}>
          <label>
            <input 
              type="radio" 
              name="desenhosTecnicos" 
              value="Sim" 
              onChange={handleInputChange} 
            /> 
            Sim
          </label>
          <label>
            <input 
              type="radio" 
              name="desenhosTecnicos" 
              value="Não" 
              onChange={handleInputChange} 
            /> 
            Não
          </label>
        </div>
      </label>

      <h2 className={style.sectionTitle}>Referências e Documentos Adicionais</h2>

      <label className={style.label}>
        Referências (opcional)
        <textarea 
          name="referencias" 
          onChange={handleInputChange} 
          className={style.textarea}
        ></textarea>
      </label>

      <label className={style.label}>
        Upload do CV (obrigatório)
        <input 
          type="file" 
          name="cvUpload" 
          onChange={handleFileChange} 
          className={style.input} 
        />
      </label>

      <label className={style.label}>
        Declaração de Compromisso
        <textarea 
          name="declaracao" 
          onChange={handleInputChange} 
          className={style.textarea}
        ></textarea>
      </label>

      <div className={style.buttonGroup}>
        <Link to={'/candidaturas'}>
          <button className={style.buttonBack}>Voltar</button>
        </Link>
        <button type="button" onClick={handleSubmit} className={style.buttonNext}>
          Seguinte
        </button>
      </div>
    </div>
  );
}

export default OperadorTornoForm;
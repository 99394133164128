import style from './ChiefFinancialOfficer.module.css'
import img from '../../../assets/assistenteadm.jpg'
import { Link } from 'react-router-dom';

import React from 'react';

function Secretaria() {
  const imgg = img;
  const titulo = 'Secretária';

  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Secretária</h1>
          <div className={style.jobDescription}>
            <p>
            A Pathway Consulting está a recrutar uma Secretária para Escritório de Advocacia, localizada na Cidade de Maputo. A profissional será responsável por apoiar na gestão de todas as actividades relacionadas com as tarefas administrativas e organizacionais, garantindo que os processos estejam em conformidade com as políticas e normas da empresa, e que as necessidades da organização sejam atendidas de forma eficiente e económica, contribuindo para o bom funcionamento do escritório.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} alt="Secretária" />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Experiência comprovada como secretária ou assistente administrativa.</li>
          <li>Proficiência em inglês (oral e escrito).</li>
          <li>Excelentes capacidades de organização e gestão de agendas.</li>
          <li>Boa comunicação verbal e escrita.</li>
          <li>Apresentação cuidada, simpatia e profissionalismo.</li>
          <li>Conhecimentos de informática (Pacote Office).</li>
          <li>Proatividade, discrição e atitude positiva.</li>
        </ul>
      </div>

      <div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Gestão de agendas e compromissos.</li>
          <li>Atendimento a clientes e recepção de visitantes.</li>
          <li>Organização e manutenção de arquivos físicos e digitais.</li>
          <li>Planeamento e coordenação de reuniões e eventos internos.</li>
          <li>Apoio administrativo à equipa jurídica e gestão do escritório.</li>
        </ul>
      </div>

      <div className={style.applySection}>
        <Link to={`/candidaturaspersonal/${encodeURIComponent(titulo)}`}>
          <button className={style.applyButton}>Candidatar-se</button>
        </Link>
      </div>
    </div>
  );
}

export default Secretaria;

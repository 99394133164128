import { Link } from 'react-router-dom';
import img from '../../../assets/veichvle.png';
import style from './ChiefFinancialOfficer.module.css';

import React from 'react';

function MecanicoVeiculos() {
  const imgg = img;
  // const titulo = 'Mecânico de Veiculos - Ligeiros e Pesados';
  const tituloo = 'Mecânico de Veiculos'
  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Mecânico de Veiculos - Ligeiros e Pesados</h1>
          <div className={style.jobDescription}>
            <p>
            A Pathway Consulting está à procura de um Mecânico de Veiculos Leves e Pesados para uma empresa do setor de petróleo e gás, com sede em Cabo Delgado. O candidato selecionado será responsável pela reparação, reconstrução, diagnóstico e resolução de problemas de veículos ligeiros e pesados, garantindo o desempenho ótimo e a segurança.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} alt="Mecânico de Veiculos - Ligeiros e Pesados" />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Licenciatura ou qualificação técnica em Mecânica, Engenharia Automóvel ou áreas relacionadas, com experiência significativa na manutenção e reparação de veículos ligeiros.</li>
          <li>Mínimo de 5 anos de experiência na reparação e manutenção de veículos ligeiros, incluindo veículos de transporte de pessoal (pickups, carrinhas, autocarros de 56 lugares e minibuses) e veículos de transporte de mercadorias (camiões, camiões basculantes e reboques).</li>
          <li>Proficiência em diagnóstico e resolução de problemas de sistemas e componentes de veículos.</li>
          <li>Capacidade de interpretar manuais técnicos, manuais de serviço e catálogos de peças.</li>
          <li>Familiaridade com ferramentas e equipamentos de manutenção utilizados em veículos ligeiros.</li>
          <li>Conhecimento das normas e regulamentos de segurança no setor de petróleo e gás, incluindo práticas de saúde e segurança (ISO 45001).</li>
          <li>Fluência em inglês.</li>
        </ul>
      </div>

      {/*<div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Gestão de faturação e emissão de notas de honorários.</li>
          <li>Gestão de compras e aquisições para o escritório.</li>
          <li>Controlo de contas.</li>
          <li>Gestão de contas a pagar e a receber.</li>
          <li>Reconciliações bancárias e elaboração de relatórios financeiros.</li>
          <li>Apoio na elaboração e acompanhamento de orçamentos.</li>
          <li>Garantir a conformidade com as normas financeiras e fiscais aplicáveis.</li>
        </ul>
      </div>*/}

      <div className={style.offerSection}>
        <h2 className={style.sectionTitle}>Oferecemos:</h2>
        <ul className={style.offerList}>
          <li>Ambiente de trabalho dinâmico e colaborativo.</li>
          <li>Oportunidades de desenvolvimento profissional.</li>
        </ul>
      </div>

      <div className={style.applySection}>
      <Link to={`/candidaturaspersonal/${encodeURIComponent(tituloo)}`}>
          <button className={style.applyButton}>Candidatar-se</button>
        </Link>
      </div>
    </div>
  );
}

export default MecanicoVeiculos;
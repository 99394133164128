import { Link } from 'react-router-dom';
import styles from './VagasComponent.module.css';

function VagasComponent() {

  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      bottom: 0,
      // behavior: 'smooth'
    });
   
};
  const vagas = [
    { titulo: "Assistente Administrativa e Financeira", data: "Jan 18, 2025", empresa: "Pathway" },
    { titulo: "Secretária", data: "Jan 18, 2025", empresa: "Pathway" },
    // { titulo: "Supervisor de Manutenção Geral", data: "Jan 28, 2025", empresa: "Pathway" },
    { titulo: "Auto Eletricista", data: "Jan 28, 2025", empresa: "Pathway" },
    { titulo: "Mecânico de Equipamentos Pesados", data: "Jan 28, 2025", empresa: "Pathway" },
    { titulo: "Mecânico de Veiculos", data: "Jan 28, 2025", empresa: "Pathway" },
    { titulo: "Operador de Torno", data: "Jan 28, 2025", empresa: "Pathway" },
    { titulo: "Canalizador Industrial", data: "Jan 28, 2025", empresa: "Pathway" },
    { titulo: "Técnico de Refrigeração", data: "Jan 28, 2025", empresa: "Pathway" },
    { titulo: "Soldador Industrial", data: "Jan 28, 2025", empresa: "Pathway" },
    
    
  ];

  return (
    <div className={styles.vagasContainer}>
      <Link to='/vagas' className={styles.link} onClick={() => handleLinkClick()}>
      <h2 className={styles.titulo}>Vagas</h2>
      </Link>
      <ul className={styles.listaVagas}>
        {vagas.map((vaga, index) => (
          <li key={index} className={`${styles.vagaItem} ${index === vagas.length - 1 ? styles.ultimoItem : ''}`}>
            <Link to={`/descricaovaga/${encodeURIComponent(vaga.titulo)}`} onClick={() => handleLinkClick()}>
              <span className={styles.tituloVaga}>{vaga.titulo}</span>
            </Link>
            <span className={styles.detalhesVaga}>
              {vaga.data} — by {vaga.empresa}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default VagasComponent;

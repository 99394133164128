import { Link, useNavigate } from 'react-router-dom';
import style from './FielInfoForm.module.css';
import { useState, useEffect } from 'react';

function AutoEletricistaForm() {
  const navigate = useNavigate();
  const [previousFormData, setPreviousFormData] = useState(null);
  const [isCheckedOutroEquipamento, setIsCheckedOutroEquipamento] = useState(false);

  const [formData, setFormData] = useState({
    experienciaAnos: '',
    equipamentos: [],
    equipamentosOutro: '',
    sistemasSensores: '',
    trabalhoIndustrial: '',
    reparacoesIndustriais: '',
    fluenciaIngles: '',
    referencias: '',
    cvUpload: null,
    declaracao: ''
  });

  useEffect(() => {
    const savedData = localStorage.getItem('candidatarPersonalFormData');
    if (savedData) {
      setPreviousFormData(JSON.parse(savedData));
    }
  }, []);

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    
    if (!checked && value === "Outros") {
      setFormData(prev => ({
        ...prev,
        [name]: prev[name].filter(v => v !== "Outros"),
        [`${name}Outro`]: ''
      }));
      return;
    }

    setFormData(prev => ({
      ...prev,
      [name]: checked
        ? [...(prev[name] || []), value]
        : prev[name].filter(v => v !== value),
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData(prev => ({
      ...prev,
      cvUpload: event.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const previousFormData = JSON.parse(localStorage.getItem('formData')) || {};
  
      const processedFormData = {
        ...formData,
        equipamentos: formData.equipamentos.map(e => e === 'Outros' ? formData.equipamentosOutro : e).filter(e => e)
      };

      const formDataWithoutFile = { ...processedFormData };
      delete formDataWithoutFile.cvUpload;
  
      const combinedData = {
        ...previousFormData,
        ...formDataWithoutFile,
      };

      const submitFormData = new FormData();
      submitFormData.append('formData', JSON.stringify(combinedData));
  
      if (formData.cvUpload) {
        submitFormData.append('cvUpload', formData.cvUpload);
      }
  
      const response = await fetch('https://candidaturas.pathway.co.mz/backend/adicionarcandidato.php', {
        method: 'POST',
        body: submitFormData,
      });
  
      const result = await response.json();
  
      if (result.success) {
        console.log('CV uploaded to:', result.cv_path);
        localStorage.setItem('formData', JSON.stringify(combinedData));
        navigate('/');
        alert("Candidatura Submetida com sucesso");
      } else {
        console.error('Erro ao salvar:', result.message);
        alert('Erro ao salvar os dados. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao salvar os dados. Por favor, tente novamente.');
    }
  };

  return (
    <div className={style.formContainer}>
      <h2 className={style.sectionTitle}>Experiência Profissional</h2>
      
      <label className={style.label}>
        Quantos anos de experiência tem como auto eletricista? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="experienciaAnos" value="Menos de 1 ano" onChange={handleInputChange} />
            Menos de 1 ano
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="1 a 3 anos" onChange={handleInputChange} />
            1 a 3 anos
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="3 a 5 anos" onChange={handleInputChange} />
            3 a 5 anos
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="Mais de 5 anos" onChange={handleInputChange} />
            Mais de 5 anos
          </label>
        </div>
      </label>

      <label className={style.label}>
        Quais equipamentos já diagnosticou? *
        <div className={style.checkboxGroup}>
          <label>
            <input type="checkbox" name="equipamentos" value="Gruas" onChange={handleCheckboxChange} />
            Gruas
          </label>
          <label>
            <input type="checkbox" name="equipamentos" value="Escavadoras" onChange={handleCheckboxChange} />
            Escavadoras
          </label>
          <label>
            <input type="checkbox" name="equipamentos" value="Empilhadores" onChange={handleCheckboxChange} />
            Empilhadores
          </label>
          <label>
            <input 
              type="checkbox" 
              name="equipamentos" 
              value="Outros" 
              onChange={(e) => {
                handleCheckboxChange(e);
                setIsCheckedOutroEquipamento(!isCheckedOutroEquipamento);
              }}
            />
            Outros (especificar)
          </label>
          {isCheckedOutroEquipamento && (
            <label className={style.label}>
              Especifique outros equipamentos
              <input type="text" name="equipamentosOutro" onChange={handleInputChange} className={style.input} />
            </label>
          )}
        </div>
      </label>

      <label className={style.label}>
        Está familiarizado com sistemas de sensores e ignição? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="sistemasSensores" value="Sim" onChange={handleInputChange} />
            Sim
          </label>
          <label>
            <input type="radio" name="sistemasSensores" value="Não" onChange={handleInputChange} />
            Não
          </label>
        </div>
      </label>

      <label className={style.label}>
        Já trabalhou com esquemas elétricos em ambientes industriais? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="trabalhoIndustrial" value="Sim, com frequência" onChange={handleInputChange} />
            Sim, com frequência
          </label>
          <label>
            <input type="radio" name="trabalhoIndustrial" value="Sim, ocasionalmente" onChange={handleInputChange} />
            Sim, ocasionalmente
          </label>
          <label>
            <input 
              type="radio" 
              name="trabalhoIndustrial" 
              value="Não, mas tenho experiência em outros tipos de sectores" 
              onChange={handleInputChange} 
            />
            Não, mas tenho experiência em outros tipos de sectores
          </label>
          <label>
            <input type="radio" name="trabalhoIndustrial" value="Não tenho experiência" onChange={handleInputChange} />
            Não tenho experiência
          </label>
        </div>
      </label>

      <label className={style.label}>
        Já realizou reparações em ambientes industriais? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="reparacoesIndustriais" value="Sim" onChange={handleInputChange} />
            Sim
          </label>
          <label>
            <input type="radio" name="reparacoesIndustriais" value="Não" onChange={handleInputChange} />
            Não
          </label>
        </div>
      </label>

      <label className={style.label}>
        Como avalia a sua fluência em inglês? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="fluenciaIngles" value="Básico" onChange={handleInputChange} />
            Básico
          </label>
          <label>
            <input type="radio" name="fluenciaIngles" value="Intermediário" onChange={handleInputChange} />
            Intermediário
          </label>
          <label>
            <input type="radio" name="fluenciaIngles" value="Avançado" onChange={handleInputChange} />
            Avançado
          </label>
          <label>
            <input type="radio" name="fluenciaIngles" value="Fluente" onChange={handleInputChange} />
            Fluente
          </label>
        </div>
      </label>

      <h2 className={style.sectionTitle}>Referências e Documentos Adicionais</h2>

      <label className={style.label}>
        Referências (opcional)
        <textarea name="referencias" onChange={handleInputChange} className={style.textarea}></textarea>
      </label>

      <label className={style.label}>
        Upload do CV (obrigatório)
        <input type="file" name="cvUpload" onChange={handleFileChange} className={style.input} />
      </label>

      <label className={style.label}>
        Declaração de Compromisso
        <textarea name="declaracao" onChange={handleInputChange} className={style.textarea}></textarea>
      </label>

      <div className={style.buttonGroup}>
        <Link to={'/candidaturas'}>
          <button className={style.buttonBack}>Voltar</button>
        </Link>
        <button type="button" onClick={handleSubmit} className={style.buttonNext}>
          Seguinte
        </button>
      </div>
    </div>
  );
}

export default AutoEletricistaForm;
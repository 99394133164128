import { Link, useNavigate } from 'react-router-dom';
import style from './FielInfoForm.module.css';
import { useState, useEffect } from 'react';

function MecanicoPesadosForm() {
  const navigate = useNavigate();
  const [previousFormData, setPreviousFormData] = useState(null);

  const [formData, setFormData] = useState({
    experienciaAnos: '',
    sistemasTrabalho: '',
    diagnosticoFalhas: '',
    experienciaSoldagem: '',
    familiaridadeBulldozers: '',
    diagnosticoRemoto: '',
    ambientesRisco: '',
    referencias: '',
    cvUpload: null,
    declaracao: ''
  });

  useEffect(() => {
    const savedData = localStorage.getItem('candidatarPersonalFormData');
    if (savedData) {
      setPreviousFormData(JSON.parse(savedData));
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData(prev => ({
      ...prev,
      cvUpload: event.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const previousFormData = JSON.parse(localStorage.getItem('formData')) || {};
  
      const formDataWithoutFile = { ...formData };
      delete formDataWithoutFile.cvUpload;
  
      const combinedData = {
        ...previousFormData,
        ...formDataWithoutFile,
      };

      const submitFormData = new FormData();
      submitFormData.append('formData', JSON.stringify(combinedData));
  
      if (formData.cvUpload) {
        submitFormData.append('cvUpload', formData.cvUpload);
      }
  
      const response = await fetch('https://candidaturas.pathway.co.mz/backend/adicionarcandidato.php', {
        method: 'POST',
        body: submitFormData,
      });
  
      const result = await response.json();
  
      if (result.success) {
        console.log('CV uploaded to:', result.cv_path);
        localStorage.setItem('formData', JSON.stringify(combinedData));
        navigate('/');
        alert("Candidatura Submetida com sucesso");
      } else {
        console.error('Erro ao salvar:', result.message);
        alert('Erro ao salvar os dados. Por favor, tente novamenteaqiii.');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao salvar os dados. Por favor, tente novamente.');
    }
  };

  return (
    <div className={style.formContainer}>
      <h2 className={style.sectionTitle}>Experiência Profissional</h2>
      
      <label className={style.label}>
        Quantos anos de experiência tem com manutenção de equipamentos pesados? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="experienciaAnos" value="Menos de 2 anos" onChange={handleInputChange} />
            Menos de 2 anos
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="2 a 5 anos" onChange={handleInputChange} />
            2 a 5 anos
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="Mais de 5 anos" onChange={handleInputChange} />
            Mais de 5 anos
          </label>
        </div>
      </label>

      <label className={style.label}>
        Quais sistemas já trabalhou? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="sistemasTrabalho" value="Hidráulicos" onChange={handleInputChange} />
            Hidráulicos
          </label>
          <label>
            <input type="radio" name="sistemasTrabalho" value="Pneumáticos" onChange={handleInputChange} />
            Pneumáticos
          </label>
          <label>
            <input type="radio" name="sistemasTrabalho" value="Pneumáticos e Hidráulicos" onChange={handleInputChange} />
            Ambos
          </label>
        </div>
      </label>

      <label className={style.label}>
        Já realizou diagnóstico de falhas em motores industriais? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="diagnosticoFalhas" value="Sim, frequentemente" onChange={handleInputChange} />
            Sim, frequentemente
          </label>
          <label>
            <input type="radio" name="diagnosticoFalhas" value="Sim, ocasionalmente" onChange={handleInputChange} />
            Sim, ocasionalmente
          </label>
          <label>
            <input type="radio" name="diagnosticoFalhas" value="Não" onChange={handleInputChange} />
            Não
          </label>
        </div>
      </label>

      <label className={style.label}>
        Qual é sua experiência com soldagem e reparo de estruturas metálicas? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="experienciaSoldagem" value="Sem experiência" onChange={handleInputChange} />
            Não tenho experiência com soldagem e reparos metálicos
          </label>
          <label>
            <input type="radio" name="experienciaSoldagem" value="Experiência limitada" onChange={handleInputChange} />
            Tenho experiência limitada com soldagem e reparos metálicos em pequenos equipamentos
          </label>
          <label>
            <input type="radio" name="experienciaSoldagem" value="Experiência com soldagem e reparos metálicos em equipamentos pesados, mas em ambientes não industriais" onChange={handleInputChange} />
            Tenho experiência com soldagem e reparos metálicos em equipamentos pesados, mas em ambientes não industriais
          </label>
          <label>
            <input type="radio" name="experienciaSoldagem" value="Experiência industrial" onChange={handleInputChange} />
            Tenho experiência com soldagem e reparos metálicos em equipamentos pesados no setor industrial
          </label>
          <label>
            <input type="radio" name="experienciaSoldagem" value="Aampla experiência com soldagem e reparos metálicos em equipamentos pesados, tanto no setor de petróleo quanto de mineração" onChange={handleInputChange} />
            Tenho ampla experiência com soldagem e reparos metálicos em equipamentos pesados, tanto no setor de petróleo quanto de mineração
          </label>
        </div>
      </label>

      <label className={style.label}>
        Está familiarizado com equipamentos como bulldozers e carregadoras? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="familiaridadeBulldozers" value="Sim" onChange={handleInputChange} />
            Sim
          </label>
          <label>
            <input type="radio" name="familiaridadeBulldozers" value="Não" onChange={handleInputChange} />
            Não
          </label>
        </div>
      </label>

      <label className={style.label}>
        Já utilizou ferramentas de diagnóstico remoto para monitorar equipamentos pesados? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="diagnosticoRemoto" value="Nunca utilizei" onChange={handleInputChange} />
            Não, nunca utilizei ferramentas de diagnóstico remoto
          </label>
          <label>
            <input type="radio" name="diagnosticoRemoto" value="Utilizei em treinamento" onChange={handleInputChange} />
            Utilizei ferramentas de diagnóstico remoto apenas em um treinamento
          </label>
          <label>
            <input type="radio" name="diagnosticoRemoto" value="Utilizei esporadicamente" onChange={handleInputChange} />
            Utilizei ferramentas de diagnóstico remoto em equipamentos, mas de forma esporádica
          </label>
          <label>
            <input type="radio" name="diagnosticoRemoto" value="Utilizei diariamente" onChange={handleInputChange} />
            Utilizei ferramentas de diagnóstico remoto em equipamentos pesados em operações diárias
          </label>
        </div>
      </label>

      <label className={style.label}>
        Tem experiência em trabalhar em ambientes de risco elevado? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="ambientesRisco" value="Sem experiência" onChange={handleInputChange} />
            Nunca trabalhei em ambientes de risco elevado
          </label>
          <label>
            <input type="radio" name="ambientesRisco" value="Experiência em outros setores" onChange={handleInputChange} />
            Tenho alguma experiência em ambientes de risco elevado, mas em setores não relacionados a petróleo ou mineração
          </label>
          <label>
            <input type="radio" name="ambientesRisco" value="Experiência supervisionada" onChange={handleInputChange} />
            Tenho experiência em ambientes de risco elevado, mas com supervisão constante
          </label>
          <label>
            <input type="radio" name="ambientesRisco" value="Experiência independente" onChange={handleInputChange} />
            Tenho experiência em ambientes de risco elevado, trabalhando de forma independente em setores industriais
          </label>
        </div>
      </label>

      <h2 className={style.sectionTitle}>Referências e Documentos Adicionais</h2>

      <label className={style.label}>
        Referências (opcional)
        <textarea name="referencias" onChange={handleInputChange} className={style.textarea}></textarea>
      </label>

      <label className={style.label}>
        Upload do CV (obrigatório)
        <input type="file" name="cvUpload" onChange={handleFileChange} className={style.input} />
      </label>

      <label className={style.label}>
        Declaração de Compromisso
        <textarea name="declaracao" onChange={handleInputChange} className={style.textarea}></textarea>
      </label>

      <div className={style.buttonGroup}>
        <Link to={'/candidaturas'}>
          <button className={style.buttonBack}>Voltar</button>
        </Link>
        <button type="button" onClick={handleSubmit} className={style.buttonNext}>
          Seguinte
        </button>
      </div>
    </div>
  );
}

export default MecanicoPesadosForm;
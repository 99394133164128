import style from './ChiefFinancialOfficer.module.css'
import img from '../../../assets/assistenteadm.jpg'
import { Link } from 'react-router-dom';

import React from 'react'

function AssistenteAdministrativa() {
  const imgg = img;
  const titulo = 'Supervisor de Manutenção Geral';

  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Assistente Administrativa e Financeira</h1>
          <div className={style.jobDescription}>
            <p>
            A Pathway Consulting está a recrutar uma Assistente Administrativa e Financeira para Escritório de Advocacia localizada na Cidade da Maputo. A profissional será responsável por apoiar na gestão de todas as actividades relacionadas com os processos administrativos e financeiros, garantindo que os processos estejam em conformidade com as políticas e normas da empresa, e que as necessidades da organização sejam atendidas de forma eficiente e económica.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} alt="Assistente Administrativa" />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Experiência mínima de 3 anos na área financeira.</li>
          <li>Proficiência em inglês (oral e escrito).</li>
          <li>Formação superior em Contabilidade, Gestão, Economia ou áreas similares.</li>
          <li>Domínio de ferramentas financeiras e software de gestão.</li>
          <li>Excel avançado.</li>
          <li>Capacidade analítica, organização e atenção ao detalhe.</li>
        </ul>
      </div>

      <div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Gestão de faturação e emissão de notas de honorários.</li>
          <li>Gestão de compras e aquisições para o escritório.</li>
          <li>Controlo de contas.</li>
          <li>Gestão de contas a pagar e a receber.</li>
          <li>Reconciliações bancárias e elaboração de relatórios financeiros.</li>
          <li>Apoio na elaboração e acompanhamento de orçamentos.</li>
          <li>Garantir a conformidade com as normas financeiras e fiscais aplicáveis.</li>
        </ul>
      </div>

      <div className={style.offerSection}>
        <h2 className={style.sectionTitle}>Oferecemos:</h2>
        <ul className={style.offerList}>
          <li>Ambiente de trabalho dinâmico e colaborativo.</li>
          <li>Oportunidades de desenvolvimento profissional.</li>
        </ul>
      </div>

      <div className={style.applySection}>
      <Link to={`/candidaturaspersonal/${encodeURIComponent(titulo)}`}>
          <button className={style.applyButton}>Candidatar-se</button>
        </Link>
      </div>
    </div>
  );
}

export default AssistenteAdministrativa;
import { useState } from 'react'
import style from './Navbar.module.css'
import { Link } from 'react-router-dom'
import logo from '../../assets/path-no-background.png'
import { Menu, X } from 'lucide-react' // Using lucide-react for icons

const scroll = () => {
  window.scrollTo(0, 0);
}

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  return (
    <nav className={style.navbar}>
      <div className={style.navbarContent}>
        <div className={style.logo}>
          <Link to='/'><img src={logo} alt='PathWay Consultoria' /></Link>
        </div>

        <button className={style.toggleButton} onClick={toggleMenu}>
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        <ul className={`${style.navLinks} ${isOpen ? style.active : ''}`}>
          <li><Link onClick={scroll} to="/">Inicio</Link></li>
          <li><Link to="/servicos">Serviços</Link></li>
          <li><Link to="/sobre">Sobre Nós</Link></li>
          <li><Link to="/vagas">Vagas</Link></li>
          <li><Link to="/contactos">Contactos</Link></li>
          {/* <li><Link to="/candidaturas"><button className={style.registar}>Registar-se</button></Link></li> */}
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
import emailjs from '@emailjs/browser';
import { useRef, useState } from "react";
import { FaCheckCircle, FaPhoneAlt, FaTimesCircle } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { IoIosGlobe } from "react-icons/io";
import { RiMailSendFill } from "react-icons/ri";
import Map from "../layout/contactoComponents/Map.jsx";

export default function Contacto() {
  const form = useRef();
  const [alertInfo, setAlertInfo] = useState({ show: false, type: '', message: '' });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_99pra1k', 'template_g3hg5ao', form.current, {
      publicKey: 'gGzCyZ8vPxL9leunU'
    })
      .then(() => {
        setAlertInfo({
          show: true,
          type: 'success',
          message: 'Mensagem enviada com sucesso!'
        });
        form.current.reset();
        setTimeout(() => setAlertInfo({ show: false, type: '', message: '' }), 5000);
      }, (err) => {
        setAlertInfo({
          show: true,
          type: 'error',
          message: `Falha ao enviar mensagem: ${err.text}`
        });
        setTimeout(() => setAlertInfo({ show: false, type: '', message: '' }), 5000);
      });
  };

  return (
    <div className="min-h-screen w-full p-4 md:p-8 bg-gray-50">
      {/* Alert Message */}
      {alertInfo.show && (
        <div className="fixed top-4 right-4 z-50 w-full max-w-sm animate-fade-in">
          <div className={`rounded-lg border p-4 ${alertInfo.type === 'success'
            ? 'bg-green-50 border-green-500'
            : 'bg-red-50 border-red-500'
            }`}>
            <div className="flex items-start space-x-3">
              {alertInfo.type === 'success' ? (
                <FaCheckCircle className="h-5 w-5 text-green-500 mt-0.5" />
              ) : (
                <FaTimesCircle className="h-5 w-5 text-red-500 mt-0.5" />
              )}
              <div className="flex-1">
                <h3 className={`font-medium ${alertInfo.type === 'success' ? 'text-green-800' : 'text-red-800'
                  }`}>
                  {alertInfo.type === 'success' ? 'Sucesso!' : 'Erro!'}
                </h3>
                <p className={`text-sm ${alertInfo.type === 'success' ? 'text-green-600' : 'text-red-600'
                  }`}>
                  {alertInfo.message}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="max-w-7xl mx-auto">
        <h1 className="font-bold text-orange-600 font-custom text-center text-3xl md:text-5xl pt-8 transition-colors duration-300 hover:text-purple-900 cursor-pointer">
          Contacte-nos
        </h1>

        <div className="w-32 md:w-48 h-1 bg-orange-600 mx-auto mt-4 transition-colors duration-300 hover:bg-purple-900"></div>

        <p className="text-center text-purple-900 py-8 text-lg md:text-2xl max-w-4xl mx-auto">
          Estamos aqui para apoiar o crescimento da sua empresa! Descubra como as nossas soluções de consultoria em Recursos Humanos e gestão de negócios podem otimizar a sua equipa e impulsionar os seus resultados. Fale connosco e explore as melhores estratégias para o sucesso do seu negócio.
        </p>

        {/* Form and Map Container */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
          {/* Contact Form */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <form ref={form} onSubmit={sendEmail} className="space-y-6">
              <h3 className="text-xl font-bold text-purple-900 mb-6">
                Envie-nos uma mensagem
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  name="user_name"
                  className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-purple-600 focus:outline-none transition-colors duration-200"
                  placeholder="Nome"
                  required
                />
                <input
                  type="email"
                  name="user_email"
                  className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-purple-600 focus:outline-none transition-colors duration-200"
                  placeholder="Email"
                  required
                />
              </div>
              <textarea
                name="message"
                placeholder="Mensagem"
                className="w-full h-40 px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-purple-600 focus:outline-none transition-colors duration-200 resize-none"
                required
              ></textarea>
              <button
                type="submit"
                className="w-full md:w-auto px-8 py-3 bg-purple-900 text-white font-bold rounded-lg hover:bg-purple-800 transition-colors duration-200"
              >
                Enviar
              </button>
            </form>
          </div>

          {/* Map */}
          <div className="rounded-lg shadow-lg overflow-hidden h-[400px] lg:h-auto">
            <Map />
          </div>
        </div>

        {/* Contact Info Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 py-8">
          {[
            {
              icon: <FaMapLocationDot className="text-orange-600 text-5xl" />,
              title: "Endereço",
              content: "Rua João de Barros Nº 367 Sommerschield, Maputo"
            },
            {
              icon: <FaPhoneAlt className="text-orange-600 text-5xl" />,
              title: "Telefone",
              content: "(+258) 87 946 7092"
            },
            {
              icon: <RiMailSendFill className="text-orange-600 text-5xl" />,
              title: "Email",
              content: "geral@pathway.co.mz"
            },
            {
              icon: <IoIosGlobe className="text-orange-600 text-5xl" />,
              title: "Website",
              content: "pathway.co.mz"
            }
          ].map((item, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg text-center transform hover:scale-105 transition-transform duration-200 cursor-pointer"
            >
              <div className="flex justify-center mb-4">
                {item.icon}
              </div>
              <h3 className="font-bold text-purple-900 mb-2">
                {item.title}
              </h3>
              <p className="text-purple-800">
                {item.content}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
import { Link, useNavigate } from 'react-router-dom';
import style from './FielInfoForm.module.css';
import { useState, useEffect } from 'react';

function TecnicoRefrigeracaoForm() {
  const navigate = useNavigate();

  const [previousFormData, setPreviousFormData] = useState(null);
  const [isCheckedOutroRefrigerante, setIsCheckedOutroRefrigerante] = useState(false);
  const [isCheckedOutroGases, setIsCheckedOutroGases] = useState(false);

  const [formData, setFormData] = useState({
    experienciaAnos: '',
    sistemasManuseados: '',
    refrigerantes: '',
    refrigerantesOutro: '',
    experienciaSistemasIndustriais: '',
    gases: [],
    gasesOutro: '',
    ambientesIndustriais: '',
    cvUpload: null,
    declaracao: '',
  });

  useEffect(() => {
    const savedData = localStorage.getItem('candidatarPersonalFormData');
    if (savedData) {
      setPreviousFormData(JSON.parse(savedData));
    }
  }, []);

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    
    if (!checked && value === "Outro") {
      setFormData(prev => ({
        ...prev,
        [name]: prev[name].filter(v => v !== "Outro"),
        [`${name}Outro`]: ''
      }));
      return;
    }

    setFormData(prev => ({
      ...prev,
      [name]: checked
        ? [...(prev[name] || []), value]
        : prev[name].filter(v => v !== value),
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData(prev => ({
      ...prev,
      cvUpload: event.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const previousFormData = JSON.parse(localStorage.getItem('formData')) || {};
  
      const processedFormData = {
        ...formData,
        gases: formData.gases.map(g => g === 'Outro' ? formData.gasesOutro : g).filter(g => g),
      };

      const formDataWithoutFile = { ...processedFormData };
      delete formDataWithoutFile.cvUpload;
  
      const combinedData = {
        ...previousFormData,
        ...formDataWithoutFile,
      };

      const submitFormData = new FormData();
      submitFormData.append('formData', JSON.stringify(combinedData));
  
      if (formData.cvUpload) {
        submitFormData.append('cvUpload', formData.cvUpload);
      }
  
      const response = await fetch('https://candidaturas.pathway.co.mz/backend/adicionarcandidato.php', {
        method: 'POST',
        body: submitFormData,
      });

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Resposta do servidor não é JSON válido");
      }

  
      const result = await response.json();
  
      if (result.success) {
        localStorage.setItem('formData', JSON.stringify(combinedData));
        navigate('/');
        alert("Candidatura Submetida com sucesso");
      } else {
        console.error('Erro ao salvar:', result.message);
        alert(result.message || 'Erro ao salvar os dados. Por favor, tente novamente.');
      }
    } catch (error) {
        console.error('Erro:', error);
        if (error.message) {
          alert(`Erro ao processar a submissão: ${error.message}`);
        } else {
          alert('Erro ao salvar os dados. Por favor, tente novamente.');
        }
      }
  };
  
  return (
    <div className={style.formContainer}>
      <h2 className={style.sectionTitle}>Experiência em Refrigeração</h2>
      
      <label className={style.label}>
        Quantos anos de experiência tem em sistemas de refrigeração? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="experienciaAnos" value="Menos de 1 ano" onChange={handleInputChange} />
            Menos de 1 ano
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="1 a 3 anos" onChange={handleInputChange} />
            1 a 3 anos
          </label>
          <label>
            <input type="radio" name="experienciaAnos" value="Mais de 3 anos" onChange={handleInputChange} />
            Mais de 3 anos
          </label>
        </div>
      </label>

      <label className={style.label}>
        Quais sistemas já manuseou? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="sistemasManuseados" value="Ar condicionado" onChange={handleInputChange} />
            Ar condicionado
          </label>
          <label>
            <input type="radio" name="sistemasManuseados" value="Refrigeração industrial" onChange={handleInputChange} />
            Refrigeração industrial
          </label>
          <label>
            <input type="radio" name="sistemasManuseados" value="Refrigeração e Ar condicionado" onChange={handleInputChange} />
            Ambos
          </label>
        </div>
      </label>

      <label className={style.label}>
        Quais tipos de refrigerantes você já utilizou em sistemas industriais? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="refrigerantes" value="R-22, R-134A e R-410A" onChange={handleInputChange} />
            R-22, R-134A e R-410A
          </label>
          <label>
            <input type="radio" name="refrigerantes" value="Apenas R-134A e R-410A" onChange={handleInputChange} />
            Apenas R-134A e R-410A
          </label>
          <label>
            <input type="radio" name="refrigerantes" value="Somente refrigerantes naturais como amônia" onChange={handleInputChange} />
            Somente refrigerantes naturais como amônia
          </label>
          <label>
            <input type="radio" name="refrigerantes" value="Não tenho experiência com refrigerantes industriais" onChange={handleInputChange} />
            Não tenho experiência com refrigerantes industriais
          </label>
          <label>
            <input 
              type="radio" 
              name="refrigerantes" 
              value="Outro" 
              onChange={(e) => {
                handleInputChange(e);
                setIsCheckedOutroRefrigerante(!isCheckedOutroRefrigerante);
              }} 
            />
            Outro (especificar)
          </label>
          {isCheckedOutroRefrigerante && (
            <label className={style.label}>
              Especifique os refrigerantes
              <input 
                type="text" 
                name="refrigerantesOutro" 
                onChange={handleInputChange} 
                className={style.input} 
              />
            </label>
          )}
        </div>
      </label>

      <label className={style.label}>
        Tem experiência com sistemas de refrigeração industrial? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="experienciaSistemasIndustriais" value="Sim, com ampla experiência em sistemas industriais" onChange={handleInputChange} />
            Sim, com ampla experiência em sistemas industriais
          </label>
          <label>
            <input type="radio" name="experienciaSistemasIndustriais" value="Sim, mas apenas com sistemas de pequeno porte" onChange={handleInputChange} />
            Sim, mas apenas com sistemas de pequeno porte
          </label>
          <label>
            <input type="radio" name="experienciaSistemasIndustriais" value="Tenho experiência apenas em refrigeração comercial" onChange={handleInputChange} />
            Tenho experiência apenas em refrigeração comercial
          </label>
          <label>
            <input type="radio" name="experienciaSistemasIndustriais" value="Não tenho experiência com sistemas industriais" onChange={handleInputChange} />
            Não tenho experiência com sistemas industriais
          </label>
        </div>
      </label>

      <label className={style.label}>
        Quais gases já utilizou? *
        <div className={style.checkboxGroup}>
          <label>
            <input 
              type="checkbox" 
              name="gases" 
              value="R-134a" 
              onChange={handleCheckboxChange} 
            /> 
            R-134a
          </label>
          <label>
            <input 
              type="checkbox" 
              name="gases" 
              value="R-410a" 
              onChange={handleCheckboxChange} 
            /> 
            R-410a
          </label>
          <label>
            <input 
              type="checkbox" 
              name="gases" 
              value="Outro" 
              onChange={(e) => {
                handleCheckboxChange(e);
                setIsCheckedOutroGases(!isCheckedOutroGases);
              }} 
            /> 
            Outro (especificar)
          </label>
          {isCheckedOutroGases && (
            <label className={style.label}>
              Especifique os gases
              <input 
                type="text" 
                name="gasesOutro" 
                onChange={handleInputChange} 
                className={style.input} 
              />
            </label>
          )}
        </div>
      </label>

      <label className={style.label}>
        Já trabalhou em ambientes industriais severos? *
        <div className={style.radioGroup}>
          <label>
            <input type="radio" name="ambientesIndustriais" value="Sim" onChange={handleInputChange} />
            Sim
          </label>
          <label>
            <input type="radio" name="ambientesIndustriais" value="Não" onChange={handleInputChange} />
            Não
          </label>
        </div>
      </label>

      <h2 className={style.sectionTitle}>Documentos Adicionais</h2>

      <label className={style.label}>
        Upload do CV (obrigatório)
        <input 
          type="file" 
          name="cvUpload" 
          onChange={handleFileChange} 
          className={style.input} 
        />
      </label>

      <label className={style.label}>
        Declaração de Compromisso
        <textarea 
          name="declaracao" 
          onChange={handleInputChange} 
          className={style.textarea}
        ></textarea>
      </label>

      <div className={style.buttonGroup}>
        <Link to={'/candidaturas'}>
          <button className={style.buttonBack}>Voltar</button>
        </Link>
        <button type="button" onClick={handleSubmit} className={style.buttonNext}>
          Seguinte
        </button>
      </div>
    </div>
  );
}

export default TecnicoRefrigeracaoForm;